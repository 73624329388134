import axios from "axios";
import { api } from "../config";

//apply base url for axios
export const axiosApi = axios.create({
  baseURL: api.url,
  validateStatus: (status) => status < 500,
});

//apply base url for axios
export const axiosApiWithoutBaseUrl = axios.create({
  validateStatus: (status) => status < 500,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

axiosApiWithoutBaseUrl.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  if (url.startsWith("https://")) {
    return axiosApiWithoutBaseUrl.get(url, { ...config });
  } else {
    return axiosApi.get(url, { ...config });
  }
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config });
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config });
}

export async function patch(url, data, config = {}) {
  return axiosApi.patch(url, { ...data }, { ...config });
}

export async function del(url, config = {}) {
  return axiosApi.delete(url, { ...config });
}
