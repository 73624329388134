import React from "react";
import { ethers } from "ethers";
import { etherscanLink } from "../../../helpers/ensuro_helper";
import "../style.scss";

const BlurredAddress = ({ str, address }) => {
  return (
    <>
      {str && str.state !== "LOADED" ? (
        <span className="blur">0x00...0000</span>
      ) : (
        <>
          {address ? (
            <span className="without-padding">
              {str.value === ethers.ZeroAddress && <>{"----"}</>}
              {str.value !== ethers.ZeroAddress && (
                <>
                  {str.value.substring(0, 4) + "..." + str.value.slice(-4)}
                  <a target="_blank" rel="noopener noreferrer" href={etherscanLink(str.value)}>
                    <i className="uil uil-external-link-alt" />
                  </a>
                </>
              )}
            </span>
          ) : (
            str.value
          )}
        </>
      )}
    </>
  );
};

export default BlurredAddress;
