import { createSelector } from "reselect";

const getSettings = (state) => state.settings;

const getSettingKey = (__, key) => {
  return key;
};

export const selectSetting = createSelector(
  [getSettings, getSettingKey],
  (settings, settingsKey) => settings[settingsKey]
);
