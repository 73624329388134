import { alchemy } from "./config";

const { ethers } = require("ethers");

const abis = {}; // Mapping (abiName => abi)
const formatters = {}; // Mapping (abiName => method => outputFormatterFunction)

const registry = {}; // Mapping (address => ethers.Contract)
const registryAbiName = {}; // Mapping (address => abiName)

export function registerABI(abiName, abi) {
  abis[abiName] = abi;
  return abis[abiName];
}

export function getABI(abiName) {
  return abis[abiName];
}

export function registerFormatter(abiName, method, formatter) {
  if (formatters[abiName] === undefined) formatters[abiName] = { [method]: formatter };
  else formatters[abiName][method] = formatter;
}

export function getFormatter(abiName, method) {
  return formatters[abiName][method];
}

export function registerContract(address, abiName, rpc) {
  const provider =
    alchemy.network !== undefined
      ? new ethers.AlchemyProvider(alchemy.network, alchemy.apiKey)
      : new ethers.JsonRpcProvider(rpc);
  const contract = new ethers.Contract(address, abis[abiName], provider);
  registry[address] = contract;
  registryAbiName[address] = abiName;
  return contract;
}

export async function getSignerContract(address, abiName, provider) {
  const signer = await provider.getSigner();
  return new ethers.Contract(address, abis[abiName], signer);
}

export function getContract(address, abiName, rpc) {
  let ret = registry[address];
  if (ret === undefined) return registerContract(address, abiName, rpc);
  else return ret;
}

export function getAbiName(address) {
  return registryAbiName[address];
}

export function getEncodedCall(address, abiName, method, args, rpc) {
  let contract = getContract(address, abiName, rpc);
  return contract.interface.encodeFunctionData(method, args);
}

export async function getTxReceiptStatus(txHash, provider) {
  let receipt = await provider.getTransactionReceipt(txHash);
  if (receipt) return receipt.status;
  else return null;
}
