import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

const Badges = ({ tags, badgesData }) => {
  tags = tags || [];
  const badges = tags ? badgesData.filter((badge) => tags.includes(badge.key)) : [];

  return (
    <>
      {badges?.map((b, idx) => (
        <React.Fragment key={idx}>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id={`tooltip-${idx}`} className="in">
                {b.description}
              </Tooltip>
            }
          >
            <Badge pill bg={b.style} className="font-size-10">
              {b.title}
            </Badge>
          </OverlayTrigger>{" "}
        </React.Fragment>
      ))}
    </>
  );
};

export default Badges;
