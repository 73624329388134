import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { toggleLeftmenu } from "../../store/actions";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import etkIcon from "../../assets/images/etoken_color.svg";
import paIcon from "../../assets/images/PA_color.svg";

const Navbar = (props) => {
  const [ui, setui] = useState(false);
  const location = useLocation();

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="topnav  d-lg-none">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/"
                    onClick={() => {
                      props.toggleLeftmenu(!props.leftMenu);
                    }}
                  >
                    <span>
                      <img src={etkIcon} alt="" height="25" />
                    </span>{" "}
                    {props.t("Pools")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/portfolios"
                    onClick={() => {
                      props.toggleLeftmenu(!props.leftMenu);
                    }}
                  >
                    <span>
                      <img src={paIcon} alt="" height="20" />
                    </span>{" "}
                    {props.t("Risk Partners")}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      setui(!ui);
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="uil-plus me-2"></i>
                    {props.t("More")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu mega-dropdown-menu dropdown-menu-left ", {
                      show: ui,
                    })}
                  >
                    <div>
                      <Link to="//docs.ensuro.co/product-docs/" target="_blank" className="dropdown-item">
                        <i className="mdi mdi-file-document-multiple-outline me-2" /> {props.t("Docs")}
                      </Link>
                      <Link to="//discord.gg/sePJF7RRZC" target="_blank" className="dropdown-item">
                        <i className="mdi mdi-discord me-2" /> {props.t("Discord")}
                      </Link>
                      <Link to="//ensuro.co" target="_blank" className="dropdown-item">
                        <i className="mdi mdi-web me-2" /> {props.t("Ensuro Website")}
                      </Link>
                      <Link to="//blog.ensuro.co" target="_blank" className="dropdown-item">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-medium"
                          viewBox="0 0 16 16"
                          style={{ marginRight: "8px" }}
                        >
                          <path d="M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8zm4.95 0c0 2.34-1.01 4.236-2.256 4.236-1.246 0-2.256-1.897-2.256-4.236 0-2.34 1.01-4.236 2.256-4.236 1.246 0 2.256 1.897 2.256 4.236zM16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795z" />
                        </svg>{" "}
                        {props.t("Blog")}
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Navbar));
