import { call, put, takeEvery, take } from "redux-saga/effects";
import { getProvider } from "../../providers";
import { eventChannel, END } from "redux-saga";

// User Redux States
import { ETH_SIWE_SIGN_PROCESSED } from "ethereum-store/src/store/ethereum/actionTypes";
import { SET_PROVIDER, SET_USER, SET_CHAIN_ID } from "./actionTypes";
import { getExpirationDate } from "../../helpers/local_storage_helper";

function providerListenerChannel(provider) {
  return eventChannel((emitter) => {
    /*if (provider.removeAllListeners)
      provider.removeAllListeners();*/
    provider.on("accountsChanged", (evtData) => {
      emitter({
        type: SET_USER,
        payload: evtData[0],
        canTransact: true, // Track Wallet can't emit this event, so it's always true
      });
    });

    provider.on("chainChanged", (evtData) => {
      emitter({
        type: SET_CHAIN_ID,
        payload: parseInt(evtData, 16),
      });
    });

    provider.on("disconnect", () => {
      emitter({
        type: SET_PROVIDER,
        payload: null,
      });
      emitter(END);
    });
    return () => {};
  });
}

export function* listenToEvents({ payload }) {
  if (payload == null) return;
  const channel = yield call(providerListenerChannel, getProvider(payload));
  try {
    while (true) {
      let action = yield take(channel);
      yield put(action);
    }
  } finally {
  }
}

export function* dispatchStoreUser({ key, message, signature, email, country, occupation, whitelist }) {
  try {
    yield put({
      type: "API_CALL",
      apiName: "storeKYC",
      args: [key],
      method: "POST",
      data: {
        address: key,
        message: message,
        signature: signature,
        email: email,
        data: {
          country: country,
          occupation: occupation,
          whitelist: whitelist,
        },
      },
    });
    localStorage.setItem(
      key,
      JSON.stringify({
        message: message,
        signature: signature,
        email: email,
        country: country,
        occupation: occupation,
        expiration: getExpirationDate(),
      })
    );
  } catch (error) {}
}

export function* userSaga() {
  yield takeEvery(SET_PROVIDER, listenToEvents);
  yield takeEvery(ETH_SIWE_SIGN_PROCESSED, dispatchStoreUser);
}

export default userSaga;
