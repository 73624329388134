import React from "react";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Table
import RisksTable from "../../components/Table/RiskTable/RisksTable";
import risks from "../../assets/images/RM_color.svg";

const Risks = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <br />
          <Breadcrumbs title="Ensuro" breadcrumbItem="Risks" logo={risks} />
          <br />
          <RisksTable />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Risks;
