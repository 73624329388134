import React from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "./style.scss";

const KYCAlerts = ({ t, state }) => {
  return (
    <>
      {state === "PENDING" && (
        <Alert variant="warning">
          <i className="uil uil-clock" /> {t("We're verifying your ID. Please wait...")}
        </Alert>
      )}

      {state === "VERIFIED" && (
        <Alert variant="warning">
          <i className="uil uil-clock" /> {t("We have verified your ID, waiting for approval...")}
        </Alert>
      )}

      {state === "APPROVED" && (
        <Alert variant="warning">
          <i className="uil uil-clock" /> {t("You have been approved, waiting for on-chain whitelist...")}
        </Alert>
      )}

      {state === "FAILED" && (
        <Alert variant="danger">
          <i className="uil uil-exclamation-octagon" /> {t("Error validating your ID, please try again.")}
        </Alert>
      )}

      {state === "WHITELISTED" && (
        <Alert variant="success">
          <i className="uil uil-exclamation-octagon" /> {t("Your wallet is whitelisted!")}
        </Alert>
      )}
    </>
  );
};

KYCAlerts.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(KYCAlerts);
