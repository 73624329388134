import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Modal, Alert, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import "./style.scss";

const RestrictedModal = ({ t, show, onHide }) => {
  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Restricted Pool")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="primary">
          {t(
            "Access to this liquidity pool is restricted. Please contact us for more information on how or when you can access"
          )}
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          <Link to="//ensuro.co/contact.html" target="_blank" className="white-text">
            {t("Contact Us")}
          </Link>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RestrictedModal.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(RestrictedModal);
