import React from "react";
import { Link } from "react-router-dom";
import "../style.scss";

const BlurredLinkedString = ({ value, linkedTo, str }) => {
  return (
    <>
      {value && value.state !== "LOADED" ? (
        <span className="blur">Ensuro</span>
      ) : (
        <Link to={linkedTo}>
          <p>{str}</p>
        </Link>
      )}
    </>
  );
};

export default BlurredLinkedString;
