// Settings Redux States
import { SET_SETTING, SET_ALL_SETTINGS, SET_SETTING_SUCCESS, SET_SETTING_FAIL } from "./actionTypes";
import { put, takeEvery } from "redux-saga/effects";

export function* setAllSettings() {
  let settingsList = [
    { key: "VIEW_MODE", default: "hide" },
    { key: "I18N_LANGUAGE", default: "en" },
  ];
  try {
    for (var s of settingsList) {
      yield put({ type: SET_SETTING_SUCCESS, settingsKey: s.key, value: localStorage.getItem(s.key) || s.default });
    }
  } catch (error) {
    yield put({ type: SET_SETTING_FAIL, payload: error.message });
  }
}

export function* updateSettings({ settingsKey, value }) {
  localStorage.setItem(settingsKey, value);
  yield put({ type: SET_SETTING_SUCCESS, settingsKey: settingsKey, value: value });
}

export function* settingsSaga() {
  yield takeEvery(SET_SETTING, updateSettings);
  yield takeEvery(SET_ALL_SETTINGS, setAllSettings);
}

export default settingsSaga;
