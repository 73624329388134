export const getExpirationDate = () => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  return date.toISOString();
};

export const getLocalStorageByKey = (key) => {
  const item = JSON.parse(localStorage.getItem(key));
  const expiration = item ? item.expiration : undefined;
  const now = new Date();
  // Si no tiene esa la key o si la tiene pero ya expiró
  if (!expiration || expiration < now.toISOString()) {
    localStorage.removeItem(key);
    return undefined;
  }
  return item;
};

export const getOrSetSignStoreValues = (sign, userAddress) => {
  let msg = undefined;
  let sigHash = undefined;
  // console.log("SIGN", sign);
  if (!sign) {
    //No estaba en el Store, lo busco en el localStorage y lo guardo en el store (--> No puedo hacer dispatch)
    let ls = getLocalStorageByKey(userAddress);
    msg = ls ? ls.message : undefined;
    sigHash = ls ? ls.signature : undefined;
  } else {
    msg = sign.message;
    sigHash = sign.signature;
    localStorage.setItem(
      userAddress,
      JSON.stringify({
        message: sign.message,
        signature: sign.signature,
        expiration: getExpirationDate(),
        email: sign.email,
        country: sign.country,
        occupation: sign.occupation,
      })
    );
  }
  return { msg, sigHash };
};
