export function createModal() {
  const overlay = document.createElement("div");
  overlay.id = "overlay";

  const customPrompt = document.createElement("div");
  customPrompt.id = "custom-prompt";

  const title = document.createElement("h4");
  title.innerText = "Track Wallet";
  customPrompt.appendChild(title);

  const info = document.createElement("div");
  info.role = "alert";
  info.className = "fade alert alert-info show";
  info.innerText =
    "Read-only mode allows to see address positions in Ensuro, but you won't be able to perform transactions.";
  customPrompt.appendChild(info);

  const addressInput = document.createElement("input");
  addressInput.type = "text";
  addressInput.id = "address-input";
  addressInput.placeholder = "Enter ethereum address here...";
  customPrompt.appendChild(addressInput);

  const errorMessage = document.createElement("p");
  errorMessage.id = "error-message";
  customPrompt.appendChild(errorMessage);

  const buttons = document.createElement("div");
  buttons.className = "buttons";

  const cancelButton = document.createElement("a");
  cancelButton.id = "cancel-button";
  cancelButton.innerText = "Cancel";
  buttons.appendChild(cancelButton);

  const confirmButton = document.createElement("button");
  confirmButton.id = "confirm-button";
  confirmButton.type = "button";
  confirmButton.className = "btn btn-secondary";
  confirmButton.innerText = "Connect";
  buttons.appendChild(confirmButton);

  customPrompt.appendChild(buttons);

  document.body.appendChild(overlay);
  document.body.appendChild(customPrompt);
}
