import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Alert } from "react-bootstrap";
/* Selectors */
import { selectProvider } from "../../store/user/selectors";
/* i18n */
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { chain } from "../../config";
import "./style.scss";

const WrongNetworkAlert = ({ t, userState }) => {
  let dispatch = useDispatch();

  const switchNetwork = async () => {
    let provider = selectProvider(userState);
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain.hexId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chain.hexId,
                chainName: chain.name,
                rpcUrls: [chain.rpc],
                blockExplorerUrls: [chain.scan],
              },
            ],
          });
          dispatch({ type: "SET_CHAIN_ID", payload: chain.id });
        } catch (addError) {
          throw addError;
        }
      }
    }
  };

  return (
    <Alert variant="danger">
      {t("PleaseSwitchNetwork", { chain })}
      <strong className="btn-text" onClick={switchNetwork}>
        {t("[Switch network]")}
      </strong>
    </Alert>
  );
};

WrongNetworkAlert.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const userState = state.UserReducer;
  return { userState };
};

export default connect(mapStateToProps)(withTranslation()(WrongNetworkAlert));
