import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import LanguageDropdown from "./LanguageDropdown";

const SettingsDropdown = (props) => {
  let dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const currentMode = localStorage.getItem("VIEW_MODE");
    setShowAll(currentMode === "show");
  }, []);

  const changeViewMode = (mode) => {
    setShowAll(mode === "show");
    dispatch({
      type: "SET_SETTING",
      settingsKey: "VIEW_MODE",
      value: mode,
    });
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block language-switch">
        <DropdownToggle style={{ padding: "10px" }} className="btn header-item waves-effect " tag="button">
          <i className="mdi mdi-cog me-2" height="30"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            <Form.Check
              reverse
              type="switch"
              label={props.t("Show inactive")}
              checked={showAll}
              onChange={() => changeViewMode(showAll ? "hide" : "show")}
            />
          </DropdownItem>
          <div style={{ paddingLeft: "24px" }}>
            <LanguageDropdown />
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

SettingsDropdown.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(SettingsDropdown);
