import React from "react";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Table
import PATable from "../../components/Table/PATable/PATable";
import pas from "../../assets/images/PA_color.svg";

const PremiumsAccounts = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <br />
          <Breadcrumbs title="Ensuro" breadcrumbItem="Risk Partners" logo={pas} />
          <br />
          <PATable />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PremiumsAccounts;
