import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Card, Row, Col, ProgressBar } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
/* Helpers */
import { compactNumber, toDecimal } from "../../helpers/number_format_helper";
/* i18n */
import { withTranslation } from "react-i18next";

import "./single_eToken.scss";

const StackedProgress = ({ t, title, data, utilizationRate, totalSupply, scr }) => {
  let fullData = [];
  if (utilizationRate && data?.value && totalSupply?.value && scr?.value) {
    fullData = data.value.map((row) => {
      let withdrawable = scr.value.sub(row.scr) / totalSupply.value;
      withdrawable = (100 - withdrawable * 100).toFixed(1);
      return {
        week: row.expires_in_periods,
        scr: totalSupply.value.sub(scr.value).add(toDecimal(row.scr)),
        withdrawable: withdrawable,
      };
    });
    fullData.unshift({
      scr: totalSupply.value.sub(scr.value).toFixed(1),
      withdrawable: (100 - utilizationRate).toFixed(1),
    });
  }

  return (
    <Card className="progress-col etk-card-detail-inside">
      {fullData.length > 0 && (
        <>
          <Row>
            <Col className="font-size-20 without-padding">
              {title}{" "}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip-2" className="p-without-padding">
                    {t("The expected amount that can be withdrawn from the eToken at any given time.")}
                  </Tooltip>
                }
              >
                <span className="fa fa-info-circle info-icon" data-toggle="tooltip" data-original-title="Info" />
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12} className="without-padding" align="center">
              <ProgressBar>
                {fullData.map((item, index) => (
                  <OverlayTrigger
                    key={index}
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2" className="p-without-padding">
                        <>
                          {index === 0 ? (
                            <p>{t("Now")}</p>
                          ) : (
                            <p>
                              {t("In")} {`${index}`} {index > 1 ? t("Weeks") : t("Week")}
                            </p>
                          )}
                          <p>
                            ${compactNumber(item.scr, { maximumFractionDigits: 2, notation: "compact" })}{" "}
                            {`(${item.withdrawable}%)`}
                          </p>
                        </>
                      </Tooltip>
                    }
                  >
                    <ProgressBar
                      style={{
                        opacity: `${
                          100 - ((item.withdrawable - fullData[0].withdrawable) / (100 - fullData[0].withdrawable)) * 95
                        }%`,
                      }}
                      key={index}
                      variant="primary"
                      now={100 / fullData.length}
                      isChild={true}
                    />
                  </OverlayTrigger>
                ))}
              </ProgressBar>
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

StackedProgress.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(StackedProgress);
