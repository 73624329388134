import React from "react";
import "./style.scss";
import image from "../../assets/images/404-image.png";

const NotFound = () => {
  return (
    <div className="page-content center-image">
      <img src={image} alt="pic" />
    </div>
  );
};

export default NotFound;
