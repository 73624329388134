// Settings Redux States
import { SET_SETTING_SUCCESS, SET_SETTING_FAIL } from "./actionTypes";

const INIT_STATE = {
  settings: {},
  error: {
    message: "",
  },
};

const SettingsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SETTING_SUCCESS:
      state = {
        ...state,
        settings: {
          ...state.settings,
          [action.settingsKey]: action.value,
        },
      };
      break;

    case SET_SETTING_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SettingsReducer;
