import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
/* Components */
import PADetail from "../../components/PremiumsAccount/PADetail";
import NotFound from "../NotFound/notFound";
import Loader from "react-js-loader";
/* Selectors */
import * as actionTypes from "../../store/premiumsAccounts/actionTypes";
import * as selectors from "../../store/premiumsAccounts/selectors";
/* i18n */
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import pas from "../../assets/images/PA_color.svg";

const PA = ({ state }) => {
  let dispatch = useDispatch();
  let current = selectors.selectCurrent(state);
  let currentAddress = selectors.selectCurrentAddr(state);
  const params = useParams();

  // Dispatch SET_SELECTED_PA if address in URL changed
  useEffect(() => {
    if (currentAddress !== params.name) {
      dispatch({ type: actionTypes.SET_SELECTED_PA, address: params.name });
    }
  }, [currentAddress, params.name, dispatch]);

  return (
    <>
      {selectors.selectCurrentExists(state) === undefined && !selectors.selectCurrentIsReady(state) ? (
        <div className="page-content loader">
          <Loader type="spinner-default" height={50} width={100} timeout={1000} />
        </div>
      ) : selectors.selectCurrentExists(state) === -1 ? (
        <NotFound />
      ) : (
        current && (
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                <Row>
                  <Col className="col-12">
                    <br />
                    <div style={{ fontSize: "28px" }}>
                      <span className="logo-sm">
                        <img src={current.logo || pas} alt="" height="35" />
                      </span>{" "}
                      {current.name}
                    </div>
                  </Col>
                  <Col className="col-12">
                    <PADetail pa={current} />
                  </Col>
                </Row>
              </Container>
            </div>
            <br />
            <br />
          </React.Fragment>
        )
      )}
    </>
  );
};

PA.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  return { state: state.PAReducer };
};

export default connect(mapStateToProps)(withTranslation()(PA));
