import React, { useState } from "react";
import PropTypes from "prop-types";
import BlurredNumber from "./Blurred/BlurredNumber";
import ExpandChartModal from "../Modal/ExpandChartModal";
import LineMinichart from "../Charts/LineMinichart";
import { Col, Card, CardBody } from "reactstrap";
import "./style.scss";

const MiniWidget = ({ data, Component, graph, dataKey, color, suffix, percentage, apiCall, address, xl = 3 }) => {
  const [chartModal, showChartModal] = useState(false);
  const expandedChart = Component === LineMinichart ? "Line" : "Bar";

  return (
    <React.Fragment>
      <Col md={6} xl={xl}>
        <Card>
          <i className="uil uil-expand-arrows-alt expand-btn float outside" onClick={() => showChartModal(true)}></i>
          <CardBody style={{ paddingTop: 0 }}>
            <div className="float-end">
              <Component graphData={graph} dataKey={dataKey} color={color} />
            </div>
            <div>
              <h4 className="mb-1 mt-1 widget-value">
                <span>
                  {data.prefix}
                  <BlurredNumber number={data.value} options={data.options} suffix={suffix} percentage={percentage} />
                </span>
              </h4>
              <p className="text-muted mb-0">{data.title}</p>
            </div>
          </CardBody>
        </Card>
      </Col>
      {chartModal && (
        <ExpandChartModal
          show={chartModal}
          onHide={() => showChartModal(false)}
          title={data.title}
          apiCall={apiCall}
          dataKey={dataKey}
          address={address}
          type={expandedChart}
          color={color}
          yLabel={data.label}
        />
      )}
    </React.Fragment>
  );
};

MiniWidget.propTypes = {
  widgets: PropTypes.array,
};

export default MiniWidget;
