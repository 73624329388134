import React from "react";
import PropTypes from "prop-types";
/* Components */
import BlurredAddress from "../CommonForBoth/Blurred/BlurredAddress";
import Parameter from "../CommonForBoth/Parameter";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Card } from "semantic-ui-react";
/* i18n */
import { withTranslation } from "react-i18next";
/* Helpers */
import { etherscanLink } from "../../helpers/ensuro_helper";
import colors from "../../colors";

const ETokenParams = ({ t, pas, address, internalLoanInterestRate, minUR, maxUR, assetManager }) => {
  return (
    <Card fluid className="params">
      <a target="_blank" className="right-aligned" rel="noopener noreferrer" href={etherscanLink(address)}>
        <p className="font-size-14">
          {t("ViewContractInBlockExplorer")} <i className="uil uil-external-link-alt" />
        </p>
      </a>
      <br />
      <Parameter
        name={t("Pool Loan Interest Rate")}
        value={internalLoanInterestRate}
        suffix={"%"}
        percentage={true}
        options={{ maximumFractionDigits: 2, notation: "standard" }}
      />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />
      <Parameter
        name={t("Min Utilization Rate")}
        value={minUR}
        suffix={"%"}
        percentage={true}
        options={{ maximumFractionDigits: 2, notation: "standard" }}
      />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Parameter
        name={t("Max Utilization Rate")}
        value={maxUR}
        suffix={"%"}
        percentage={true}
        options={{ maximumFractionDigits: 2, notation: "standard" }}
      />
      {assetManager && assetManager.am && (
        <>
          <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />
          <Row>
            <Col md={7} xs={7}>
              <p>
                <strong>{t("Asset Manager")}</strong>
              </p>
            </Col>
            <Col md={5} xs={5} className="params-value">
              {assetManager.text ? (
                <a target="_blank" rel="noopener noreferrer" href={etherscanLink(assetManager.am?.value)}>
                  <p>
                    {assetManager.text} <i className="uil uil-external-link-alt" />
                  </p>
                </a>
              ) : (
                <BlurredAddress str={assetManager.am} address={true} />
              )}
            </Col>
          </Row>
        </>
      )}
      {pas && (
        <>
          <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />
          <Row>
            <Col md={7} xs={7}>
              <p>
                <strong>{t("Risk Partners")}</strong>
              </p>
            </Col>
            {pas.map((pa, idx) => (
              <React.Fragment key={idx}>
                <Col md={5} xs={5} className="params-value">
                  <Link to={`/portfolios/${pa.address}`}>
                    <p>{pa.name}</p>
                  </Link>
                </Col>
                <Col md={7} xs={7}></Col>
              </React.Fragment>
            ))}
          </Row>
        </>
      )}
    </Card>
  );
};

ETokenParams.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(ETokenParams);
