import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Parameter from "../CommonForBoth/Parameter";
import BlurredNumber from "../CommonForBoth/Blurred/BlurredNumber";
import BlurredAddress from "../CommonForBoth/Blurred/BlurredAddress";
import { calcOnLoad, etherscanLink } from "../../helpers/ensuro_helper";
import colors from "../../colors";
import "./style.scss";

const PAParams = ({
  t,
  address,
  jrLoanLimit,
  srLoanLimit,
  assetManager,
  deficitRatio,
  activePurePremiums,
  jrEtk,
  srEtk,
}) => {
  return (
    <Card fluid className="params">
      <a target="_blank" className="right-aligned" rel="noopener noreferrer" href={etherscanLink(address)}>
        <p className="font-size-14">
          {t("ViewContractInBlockExplorer")} <i className="uil uil-external-link-alt" />
        </p>
      </a>
      <br />
      <Parameter
        name={t("JR Loan Limit")}
        value={jrLoanLimit}
        isString={jrLoanLimit?.value === "∞"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />
      <Parameter
        name={t("SR Loan Limit")}
        value={srLoanLimit}
        isString={srLoanLimit?.value === "∞"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />

      {assetManager && assetManager.am && (
        <>
          <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />
          <Row>
            <Col md={7} xs={7}>
              <p>
                <strong>{t("Asset Manager")}</strong>
              </p>
            </Col>
            <Col md={5} xs={5} className="params-value">
              {assetManager.text ? (
                <a target="_blank" rel="noopener noreferrer" href={etherscanLink(assetManager.am?.value)}>
                  <p>
                    {assetManager.text} <i className="uil uil-external-link-alt" />
                  </p>
                </a>
              ) : (
                <BlurredAddress str={assetManager.am} address={true} />
              )}
            </Col>
          </Row>
        </>
      )}
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Row>
        <Col md={8} xs={8}>
          <p>
            <strong>{t("Max Deficit")}</strong>
          </p>
        </Col>
        <Col md={4} xs={4} className="params-value">
          <BlurredNumber
            number={calcOnLoad((a, b) => a * b, deficitRatio, activePurePremiums)}
            options={{ maximumFractionDigits: 2, notation: "compact" }}
          />
        </Col>
      </Row>
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Row>
        <Col md={7} xs={6}>
          <p>
            <strong>{t("Junior Pool")}</strong>
          </p>
        </Col>
        <Col md={5} xs={6} className="params-value">
          <Link to={jrEtk?.address ? `/etokens/${jrEtk.address}` : "/etokens"}>
            <p>{jrEtk?.name}</p>
          </Link>
        </Col>
      </Row>
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Row>
        <Col md={7} xs={6}>
          <p>
            <strong>{t("Senior Pool")}</strong>
          </p>
        </Col>
        <Col md={5} xs={6} className="params-value">
          <Link to={srEtk?.address ? `/etokens/${srEtk.address}` : "/etokens"}>
            <p>{srEtk?.name}</p>
          </Link>
        </Col>
      </Row>
      <br />
    </Card>
  );
};

PAParams.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(PAParams);
