import React, { useState } from "react";
import { Card, CardBody, Col, Row, CardTitle, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PricingParams from "./pricingParams.json";

const gaussian = require("gaussian");

function calculatedFields({ trackRecord, expectedLoss, qPolicies, correlation, duration, maxPayout }) {
  let qPoliciesLog10 = Math.floor(Math.log10(qPolicies));
  if (qPoliciesLog10 <= 0) qPoliciesLog10 = 1;
  else if (qPoliciesLog10 > 3) qPoliciesLog10 = 3;

  let pricingKey = trackRecord + "-" + correlation + "-" + qPoliciesLog10;
  let pricingValues = PricingParams[pricingKey];

  if (pricingValues === undefined) {
    console.log("Price not found", pricingKey);
    return {};
  }

  let { moc, varianceMultiplier, juniorRoC, seniorRoC, ensPPFee, ensCoCFee } = pricingValues;

  let purePremium = expectedLoss * moc;
  let mocLossProb = purePremium / maxPayout;
  let lossProb = expectedLoss / maxPayout;
  let mean = mocLossProb * qPolicies;
  let variance = qPolicies * mocLossProb * (1 - mocLossProb);
  variance *= varianceMultiplier;
  let distribution = gaussian(mean, variance);
  let juniorCollRatio = distribution.ppf(0.8) / qPolicies; // 80%
  if (juniorCollRatio > 1) juniorCollRatio = 1;

  let seniorCollRatio = distribution.ppf(0.995) / qPolicies; // 99.5%
  if (seniorCollRatio > 1) seniorCollRatio = 1;

  // Calculate Junior and Senior CoC
  let juniorSCR = maxPayout * juniorCollRatio - purePremium;
  if (juniorSCR < 0) juniorSCR = 0;
  let juniorCoC = juniorSCR * (duration / 365) * juniorRoC;
  let seniorSCR = maxPayout * (seniorCollRatio - juniorCollRatio);
  if (seniorSCR < 0) seniorSCR = 0;
  let seniorCoC = seniorSCR * (duration / 365) * seniorRoC;

  let ensuroFee = ensPPFee * purePremium + ensCoCFee * (juniorCoC + seniorCoC);

  let costPerPolicy = purePremium + juniorCoC + seniorCoC + ensuroFee;
  costPerPolicy = Math.round((costPerPolicy + Number.EPSILON) * 100) / 100;

  return {
    moc,
    costPerPolicy,
    purePremium,
    mean,
    variance,
    juniorRoC,
    juniorCoC,
    juniorCollRatio,
    seniorCoC,
    seniorRoC,
    seniorCollRatio,
    ensuroFee,
    pricingValues,
    mocLossProb,
    lossProb,
  };
}

const BasicElements = () => {
  const initialParams = {
    qPolicies: 1000,
    maxPayout: 100,
    expectedLoss: 5,
    duration: 90,
    correlation: "Low",
    trackRecord: "None",
  };
  const [state, setState] = useState({ ...initialParams, quote: calculatedFields(initialParams) });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => {
      let theValue = isNaN(parseFloat(value)) ? value : parseFloat(value);
      let newState = { ...prevState, [name]: theValue };
      try {
        newState = { ...newState, quote: calculatedFields(newState) };
      } catch (error) {
        console.log("Error calculating quote", error, newState);
      }
      return newState;
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Get A Quote" />

          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Portfolio parameters</CardTitle>
                  <p className="card-title-desc">
                    Please respond to a few questions about the portfolio you want to (re)insure with us. Note the quote
                    is just an estimation and would require the validation of our quant and business teams.
                  </p>

                  <Row className="mb-3">
                    <label htmlFor="policies-per-month" className="col-md-4 col-form-label">
                      # of policies per quarter
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        value={state.qPolicies}
                        onChange={handleChange}
                        name="qPolicies"
                        id="policies-per-quarter"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label htmlFor="average-max-payout" className="col-md-4 col-form-label">
                      Average payout limit (per policy), $
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        value={state.maxPayout}
                        onChange={handleChange}
                        name="maxPayout"
                        id="average-max-payout"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label htmlFor="expected-loss" className="col-md-4 col-form-label">
                      Expected loss (per policy), $
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        value={state.expectedLoss}
                        onChange={handleChange}
                        name="expectedLoss"
                        id="expected-loss"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label htmlFor="duration" className="col-md-4 col-form-label">
                      Average Policy Duration (days)
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="number"
                        value={state.duration}
                        onChange={handleChange}
                        name="duration"
                        id="duration"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-4 col-form-label">Correlation</label>
                    <div className="col-md-8">
                      <select name="correlation" className="form-control" onChange={handleChange}>
                        <option>Low</option>
                        <option>Mid</option>
                        <option>High</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-4 col-form-label">Track Record</label>
                    <div className="col-md-8">
                      <select name="trackRecord" className="form-control" onChange={handleChange}>
                        <option>None</option>
                        <option>Some</option>
                        <option>Plenty</option>
                      </select>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Draft Quote</CardTitle>
                  {state.quote && <h5> Cost Per Policy: {state.quote.costPerPolicy} </h5>}
                  {state.quote && window.location.href.includes("expert=1") && (
                    <p>
                      Pure Premium: {state.quote.purePremium} <br />
                      Mean: {state.quote.mean} <br />
                      Variance: {state.quote.variance} <br />
                      MoC: {state.quote.moc} <br />
                      JuniorCoC: {state.quote.juniorCoC} <br />
                      SeniorCoC: {state.quote.seniorCoC} <br />
                      Junior Coll Ratio: {state.quote.juniorCollRatio} <br />
                      Senior Coll Ratio: {state.quote.seniorCollRatio} <br />
                      EnsuroFee: {state.quote.ensuroFee} <br />
                      Loss Prob: {state.quote.lossProb} <br />
                      MoC adjusted Loss Prob: {state.quote.mocLossProb} <br />
                      PricingValues: {JSON.stringify(state.quote.pricingValues)} <br />
                    </p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BasicElements;
