import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { shortAddress } from "../../helpers/wallet_store_helper";
import { etherscanLink } from "../../helpers/ensuro_helper";
import { withTranslation } from "react-i18next";

const Address = ({ t, address, placement }) => {
  const [copied, setCopied] = useState(false);

  const copy = async (address) => {
    try {
      await navigator.clipboard.writeText(address);
      setCopied(true);
    } catch (e) {
      setCopied(false);
      throw e;
    } finally {
      setTimeout(() => setCopied(false), 3000);
    }
  };

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="top"
      overlay={
        <Popover>
          <Popover.Body>
            <InputGroup className="mb-3">
              <InputGroup.Text>{address}</InputGroup.Text>
              <InputGroup.Text style={{ cursor: "pointer" }} onClick={() => copy(address)}>
                {copied ? <i className="uil uil-check" /> : <i className="uil uil-copy" />}
              </InputGroup.Text>
            </InputGroup>
            <a target="_blank" rel="noopener noreferrer" href={etherscanLink(address)}>
              <p className="font-size-14">
                <i className="uil uil-external-link-alt" /> {t("ViewContractInBlockExplorer")}
              </p>
            </a>
          </Popover.Body>
        </Popover>
      }
    >
      <span style={{ cursor: "pointer" }}>{shortAddress(address)}</span>
    </OverlayTrigger>
  );
};

Address.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Address);
