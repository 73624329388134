import _ from "lodash";
import { refresh } from "../config";

export function dispatchAPICall(dispatch, call) {
  return dispatch({
    type: "API_CALL",
    apiName: call.apiName,
    args: call.args,
    headers: call.headers,
    method: call.method || "GET",
    forceCall: call.forceCall || false,
  });
}

export function dispatchAPICalls(dispatch, calls) {
  _.forEach(calls, (call) => dispatchAPICall(dispatch, call));
}

/**
 * Dispatchs calls every `refreshInterval || refresh.default`
 */
export function refreshAPICalls(dispatch, calls, refreshInterval = undefined, skipFirst = false) {
  if (!skipFirst) {
    dispatchAPICalls(dispatch, calls);
  }
  const interval = setInterval(() => dispatchAPICalls(dispatch, calls), refreshInterval || refresh.default);
  return () => clearInterval(interval);
}
