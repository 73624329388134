import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import { QuadClient, QuadAttribute } from "@quadrata/client-react";
import { Modal, Button } from "react-bootstrap";
import PassportModal from "./PassportModal";
import WelcomeModal from "./WelcomeModal";
import SignInModal from "./SignInModal";
import Loader from "react-js-loader";
import KYCAlerts from "./KYCAlerts";
import Web3 from "web3";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
/* Store */
import { selectAPICall, selectAPICallMultiple } from "../../store/api/selectors";
import { selectLastTransact, selectSign } from "ethereum-store/src/store/ethereum/selectors";
import { selectUserAddress } from "../../store/user/selectors";
/* Helpers */
import { refreshAPICalls } from "../../helpers/api_store_helper";
import { chain, quadrata } from "../../config";
import "@quadrata/core-react/lib/cjs/quadrata-ui.min.css";
import "./style.scss";

// Required Attributes
const requiredAttributes = quadrata.requiredAttributes.map((attr) => QuadAttribute[attr]);

const makeAPICalls = (userAddress, accessToken) => {
  return [
    { apiName: "checkUser", args: [userAddress], forceCall: true },
    {
      apiName: "quadAttributes",
      args: [userAddress, chain.id, requiredAttributes.map((attr) => attr.toLowerCase()).join(",")],
      headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
      forceCall: true,
    },
  ];
};

// Outside of your react component
const quadConfig = {
  apiUrl: quadrata.apiUrl,
  protocolName: "Ensuro",
  tokenId: quadrata.tokenId,
  environment: quadrata.environment,
  showSocialButtons: false,
};

const QuadrataModal = ({
  t,
  userAddress,
  show,
  onHide,
  onContinue,
  checkUser,
  accessToken,
  attributesToOnboard,
  welcomeSignature,
  txHash,
  completed,
}) => {
  let dispatch = useDispatch();
  const [signInModal, setSignInModal] = useState(false);
  const [quadModal, setQuadModal] = useState(false);
  const verifyUser = useRef(false);

  useEffect(() => {
    if (!accessToken && show) {
      dispatch({
        type: "API_CALL",
        apiName: "accessToken",
        args: [],
        method: "POST",
      });
    } else if (userAddress && accessToken && show) {
      return refreshAPICalls(dispatch, makeAPICalls(userAddress, accessToken), 1000);
    }
  }, [show, accessToken, dispatch, userAddress]);

  const signMessage = async (message) => {
    dispatch({ type: "ETH_PLAIN_SIGN", message: message, userAddress: userAddress });
  };

  const openDeposit = () => {
    onHide();
    onContinue();
  };

  const mintPassport = (mintParams) => {
    dispatch({
      type: "ETH_TRANSACT",
      address: quadrata.passportAddress,
      abi: "QuadrataPassport",
      method: "setAttributesBulk",
      args: [mintParams.params, mintParams.signaturesIssuers, mintParams.signatures],
    });
  };

  const showSignInModal = () => {
    onHide();
    setSignInModal(true);
  };

  const getKYCState = () => {
    if (!checkUser || (checkUser && !checkUser.value)) return "SIGN_IN";
    return {
      kyc_pending: "PENDING",
      kyc_verified: "VERIFIED",
      kyc_approved: "APPROVED",
      kyc_failed: "FAILED",
      whitelist: "WHITELISTED",
    }[checkUser.value.status];
  };

  let state = getKYCState();

  useEffect(() => {
    if (
      !verifyUser.current &&
      show &&
      attributesToOnboard &&
      attributesToOnboard.value &&
      !attributesToOnboard.value.length &&
      (state === "PENDING" || state === "APPROVED")
    ) {
      dispatch({
        type: "API_CALL",
        apiName: "refreshWallet",
        args: [userAddress],
        method: "POST",
      });
      verifyUser.current = true; // call only once
    }
  }, [show, attributesToOnboard, state, userAddress, dispatch]);

  return (
    <>
      {!accessToken || !attributesToOnboard || (attributesToOnboard && !attributesToOnboard.value) ? (
        <Modal show={show} onHide={onHide} centered>
          <Loader type="spinner-default" height={50} width={100} />{" "}
        </Modal>
      ) : accessToken && !!attributesToOnboard.value?.length ? (
        quadModal ? (
          <QuadClient
            config={quadConfig} // Custom Configuration (i.e endpoint, protocol name)
            accessToken={accessToken} // JWT token obtained by calling /v1/api/login
            account={userAddress} // wallet address of the user to onboard
            chainId={chain.id} // Blockchain chainId to onboard to (see: https://chainlist.org/)
            attributes={attributesToOnboard.value} // list of attributes required by the protocol
            onSign={signMessage} // Callback function: triggered when a user clicks "Sign Wallet"
            signature={welcomeSignature} // store the result of the callback `onSign`
            onMintClick={mintPassport} // Callback function: triggered when a user clicks "Claim Passport"
            mintComplete={completed} // Boolean flag indicating when the "passport minting" is completed
            transactionHash={txHash} // The mint blockchain transaction hash
            onHide={() => setQuadModal(false)}
          >
            <div className="loader with-padding">
              <Loader type="spinner-default" height={50} width={100} />
            </div>
          </QuadClient>
        ) : (
          <WelcomeModal show={show} onHide={onHide} openQuad={() => setQuadModal(true)} />
        )
      ) : state === "SIGN_IN" ? (
        <PassportModal show={show} onHide={onHide} onClick={showSignInModal} tokenId={quadConfig.tokenId} />
      ) : (
        <Modal show={show} onHide={onHide} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("KYC")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <KYCAlerts state={state} />
          </Modal.Body>
          {state === "WHITELISTED" && (
            <Modal.Footer>
              <Button variant="primary" onClick={openDeposit}>
                {t("Continue")}
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      )}
      <SignInModal show={signInModal} onHide={() => setSignInModal(false)} state={state} />
    </>
  );
};

QuadrataModal.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  let userAddress = selectUserAddress(state.UserReducer);
  userAddress = userAddress ? Web3.utils.toChecksumAddress(userAddress) : undefined;
  const accessToken = selectAPICall(state.APIReducer, "accessToken", [userAddress]);

  const lastTx = selectLastTransact(state.EthereumReducer);
  const txHash = lastTx && lastTx.state === "MINED" ? lastTx.txHash : undefined;
  const completed = lastTx && lastTx.state === "MINED" ? true : false;

  const sign = selectSign(state.EthereumReducer, [userAddress]);
  const welcomeSignature = sign && sign.state === "SIGNED" ? sign.signature : undefined;

  const [checkUser, attributesToOnboard] = !accessToken
    ? [{}, []]
    : selectAPICallMultiple(state.APIReducer, makeAPICalls(userAddress, accessToken));

  return {
    userAddress,
    accessToken,
    checkUser,
    attributesToOnboard,
    welcomeSignature,
    txHash,
    completed,
  };
};

export default connect(mapStateToProps)(withTranslation()(QuadrataModal));
