// @flow
import { CHANGE_LAYOUT, TOGGLE_LEFTMENU } from "./actionTypes";

const INIT_STATE = {
  layoutType: "horizontal",
  leftMenu: false,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };

    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      };

    default:
      return state;
  }
};

export default Layout;
