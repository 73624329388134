import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
/* Components */
import { Card } from "semantic-ui-react";
import { Col, Row } from "reactstrap";
import Loader from "react-js-loader";
import PeriodFilter from "../CommonForBoth/PeriodFilter";
import StackedBarChart from "../Charts/StackedBarChart";
/* Helpers and Selectors */
import { selectAPICallMultiple } from "../../store/api/selectors";
import { selectCurrentAddr } from "../../store/eTokens/selectors";
import { refreshAPICalls } from "../../helpers/api_store_helper";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import colors from "../../colors";

const makeAPICalls = (etkAddress, period) => {
  return [{ apiName: "compositionHistory", args: [etkAddress, period] }];
};

const DurationSection = ({ t, state, etkAddress }) => {
  let dispatch = useDispatch();
  const [period, setPeriod] = useState(7);
  const [data] = selectAPICallMultiple(state.APIReducer, makeAPICalls(etkAddress, period));

  useEffect(() => {
    return refreshAPICalls(dispatch, makeAPICalls(etkAddress, period));
  }, [etkAddress, period, dispatch]);

  return (
    <>
      {data.state !== "LOADED" ? (
        <div className="page-content loader">
          <Loader type="spinner-default" height={50} width={100} timeout={1000} />
        </div>
      ) : (
        <>
          {data.value?.length > 0 && (
            <Row>
              <Col md={12}>
                <Card className="etk-card-detail-inside" style={{ marginBottom: "0" }}>
                  <hr style={{ width: "25%", color: colors.primaryDark }} />
                  <h4>{t("Duration and Composition")}</h4>
                  <PeriodFilter period={period} setPeriod={setPeriod} />
                </Card>
              </Col>
              <Col md={12} align="center">
                <Card fluid className="centered-card-without-borders">
                  <StackedBarChart period={period} data={data} />
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

DurationSection.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const etkAddress = selectCurrentAddr(state.ETokensReducer);
  return { etkAddress, state };
};

export default connect(mapStateToProps)(withTranslation()(DurationSection));
