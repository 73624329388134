import React from "react";
import PropTypes from "prop-types";
/* Selectors */
import { selectProvider, selectChainId } from "../../store/user/selectors";
/* i18n */
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
/* config */
import { chain } from "../../config";
import "./style.scss";

const SwitchNetworkButton = (props) => {
  let dispatch = useDispatch();

  const switchNetwork = async () => {
    let provider = selectProvider(props.user);
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain.hexId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chain.hexId,
                chainName: chain.name,
                rpcUrls: [chain.rpc],
                blockExplorerUrls: [chain.scan],
              },
            ],
          });
          dispatch({ type: "SET_CHAIN_ID", payload: chain.id });
        } catch (addError) {
          throw addError;
        }
      }
    }
  };

  return (
    <div className="d-lg-inline-block">
      {!props.user.address ? (
        <></>
      ) : selectChainId(props.user) !== chain.id ? (
        <button onClick={switchNetwork} className="font-size-18 wallet-btn btn header-item waves-effect">
          <i className="uil uil-exchange-alt"></i>
          {props.t("Switch Network")}
        </button>
      ) : (
        <>
          <button className="font-size-18 wallet-btn btn header-item waves-effect">
            <i className="uil uil-check-circle" />
            {chain.name}
          </button>
        </>
      )}
    </div>
  );
};

SwitchNetworkButton.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(SwitchNetworkButton);
