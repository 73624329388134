import React, { useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
/* Store */
import * as rmActionTypes from "../../store/risks/actionTypes";
import { selectRiskNames } from "../../store/risks/selectors";
/* i18n */
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import colors from "../../colors";
import "./style.scss";

const stackedColors = [
  colors.green,
  colors.pink,
  colors.skyBlue,
  colors.primaryLight,
  colors.secondaryLight,
  colors.primary,
  colors.primaryDark,
  colors.secondary,
  colors.secondaryDark,
];

const StackedBarChart = ({ data, period, riskModules }) => {
  let dispatch = useDispatch();
  let fullData = data.value || [];
  const rms = fullData.length ? Object.keys(fullData[0]).filter((key) => key !== "days") : [];
  const xLabel = period === 1 ? "Days" : period === 7 ? "Weeks" : "Months";

  useEffect(() => {
    dispatch({ type: rmActionTypes.GET_RISKS_MODULES });
  }, [dispatch]);

  function CustomTooltip({ payload, label, active }) {
    if (active && payload && payload?.length > 0) {
      return (
        <div className="custom-tooltip">
          <b>
            {xLabel}: {label}
          </b>
          {payload.map((_, index) => (
            <p key={index} style={{ color: stackedColors[index], margin: "auto", fontWeight: "500" }}>{`${
              riskModules[payload[index]?.dataKey]
            }: $${payload[index]?.value.toFixed(2)}`}</p>
          ))}
        </div>
      );
    }
    return null;
  }

  return (
    <div className="highlight-bar-charts" style={{ userSelect: "none", width: "95%" }}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={500}
          height={300}
          data={fullData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="days" label={{ value: xLabel, position: "insideCenter", dy: 12 }} />
          <YAxis
            label={{
              value: "Locked SCR [$]",
              angle: -90,
              position: "insideLeft",
              dy: 40,
              dx: -15,
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          {rms.map((rm, index) => (
            <Bar dataKey={rm} key={index} stackId="a" fill={stackedColors[index]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

StackedBarChart.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const riskModules = selectRiskNames(state.RisksReducer);
  return { riskModules };
};

export default connect(mapStateToProps)(withTranslation()(StackedBarChart));
