import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

const PeriodFilter = ({ t, period, setPeriod }) => {
  const label = period === 1 ? "DAY" : period === 7 ? "WEEK" : "MONTH";
  const [clicked, setClicked] = useState(label);

  return (
    <Row className="days-buttons-padding">
      <div align="right">
        <Button
          className="risk-button"
          size="sm"
          variant={clicked === "DAY" ? "secondary" : "sky-blue"}
          onClick={() => {
            setPeriod(1);
            setClicked("DAY");
          }}
        >
          {t("DAY")}
        </Button>
        <Button
          className="risk-button"
          size="sm"
          variant={clicked === "WEEK" ? "secondary" : "sky-blue"}
          onClick={() => {
            setPeriod(7);
            setClicked("WEEK");
          }}
        >
          {t("WEEK")}
        </Button>
        <Button
          className="risk-button"
          size="sm"
          variant={clicked === "MONTH" ? "secondary" : "sky-blue"}
          onClick={() => {
            setPeriod(30);
            setClicked("MONTH");
          }}
        >
          {t("MONTH")}
        </Button>
      </div>
    </Row>
  );
};

PeriodFilter.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(PeriodFilter);
