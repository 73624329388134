import { createSelector } from "reselect";
import _ from "lodash";

const getETokenList = (state) => state.eTokens;
const getLoadingState = (state) => state.loadingState;
const getSelectedEToken = (state) => state.selectedEToken;

// When exists return an index >= 0
// When NOT exists returns -1
// When I don't know yet because eTokens not loaded returns undefined
export const selectCurrentExists = createSelector(
  [getETokenList, getSelectedEToken, getLoadingState],
  (eTokens, current, loadingState) => {
    if (loadingState !== "LOADED") return undefined;
    return _.findIndex(eTokens, (etk) => etk.address === current);
  }
);

export const selectCurrent = createSelector([getETokenList, getSelectedEToken], (eTokens, current) => {
  return _.find(eTokens, (etk) => etk.address === current);
});

export const selectCurrentAddr = createSelector(getSelectedEToken, (current) => current);

export const selectCurrentIsReady = createSelector([selectCurrent], (current) => {
  return current !== undefined && current.data !== undefined;
});

export const selectAllReady = createSelector([getETokenList, getLoadingState], (eTokens, loadingState) => {
  let etk = _.find(eTokens, (etk) => etk.data === undefined);
  return eTokens.length > 0 && loadingState === "LOADED" && !etk;
});

export const selectEtkByAddress = createSelector([getETokenList, (__, address) => address], (etokens, address) => {
  let etk = _.find(etokens, (etk) => etk.address === address);
  return etk;
});

export const selectETKNames = createSelector([getETokenList], (etk) => {
  let dict = {};
  etk.forEach((etk) => {
    dict[etk.address] = etk.name;
  });
  return dict;
});
