import { select } from "redux-saga/effects";
import { call, put, takeEvery } from "redux-saga/effects";
import { selectCurrent } from "./selectors";

// PA Redux States
import {
  GET_PREMIUMS_ACCOUNTS,
  GET_PREMIUMS_ACCOUNTS_SUCCESS,
  GET_PREMIUMS_ACCOUNTS_FAIL,
  SET_SELECTED_PA,
} from "./actionTypes";

import { getPremiumsAccounts } from "../../helpers/api_calls";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* fetchPAs({ retry }) {
  try {
    const response = yield call(getPremiumsAccounts);
    yield put({ type: GET_PREMIUMS_ACCOUNTS_SUCCESS, payload: response.data });
  } catch (error) {
    delay(100);
    if (!retry || retry < 10) {
      yield put({ type: GET_PREMIUMS_ACCOUNTS, retry: (retry || 0) + 1 });
    } else {
      yield put({ type: GET_PREMIUMS_ACCOUNTS_FAIL, payload: error.message });
    }
  }
}

export function* updateSelectedPAData() {
  const state = yield select((state) => state.PAReducer);
  if (state.loadingState !== "LOADED" || selectCurrent(state) === undefined) {
    yield put({ type: GET_PREMIUMS_ACCOUNTS });
  }
}

export function* pasSaga() {
  yield takeEvery(GET_PREMIUMS_ACCOUNTS, fetchPAs);
  yield takeEvery(SET_SELECTED_PA, updateSelectedPAData);
}

export default pasSaga;
