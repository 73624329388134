import React, { useState } from "react";
import PropTypes from "prop-types";
/* Components */
import { Container, Row, Col } from "react-bootstrap";
import CustomTooltip from "../../CommonForBoth/CustomTooltip";
import ETokenRowDesktop from "./ETokenRowDesktop";
import ETokenRowMobile from "./ETokenRowMobile";
import SrETokenCard from "./SrETokenCard";
/* Helpers and Selectors */
import { checkVisibility, isJrOfSrFilter, isSrPool } from "../../../helpers/ensuro_helper";
import { selectEthCall } from "ethereum-store/src/store/ethereum/selectors";
/* i18n */
import { withTranslation } from "react-i18next";
import "./../style.scss";

const ETokens = ({ t, eTokens, state, mode }) => {
  const srPools = eTokens.filter((eToken) => isSrPool(eToken) && checkVisibility(eToken, mode));
  const [srEtkFilter, setSrEtkFilter] = useState(srPools.map((etk) => etk.address));
  const [jrPools, setJrPools] = useState(eTokens);

  const poolBadges = [
    { key: "kyc", title: t("KYC"), style: "primary", description: t("KYCBadgeTooltip") },
    { key: "restricted", title: t("Restricted"), style: "secondary", description: t("RestrictedBadgeTooltip") },
    { key: "oversold", title: t("Oversold"), style: "danger", description: t("OversoldBadgeTooltip") },
    { key: "low-risk", title: t("Low-Risk"), style: "low-risk", description: t("LowRiskBadgeTooltip") },
    { key: "mid-risk", title: t("Medium-Risk"), style: "mid-risk", description: t("MediumRiskBadgeTooltip") },
    { key: "high-risk", title: t("High-Risk"), style: "high-risk", description: t("HighRiskBadgeTooltip") },
  ];

  const getTotalSupply = function (etk) {
    return selectEthCall(state.EthereumReducer, etk.address, "EToken", "totalSupply") || -1;
  };

  const addSrEtkFilter = (eToken) => {
    if (srEtkFilter.indexOf(eToken.address) >= 0) {
      srEtkFilter.splice(srEtkFilter.indexOf(eToken.address), 1);
    } else {
      srEtkFilter.push(eToken.address);
    }
    setSrEtkFilter(srEtkFilter);

    const jrs = eTokens
      .filter((eToken) => eToken.sr_etks?.some((srEtk) => srEtkFilter.includes(new URL(srEtk).pathname.split("/")[3])))
      .sort((e1, e2) => getTotalSupply(e2) - getTotalSupply(e1));

    setJrPools(jrs);
  };

  return (
    <>
      <Row className="flex-nowrap" style={{ overflowX: "auto" }}>
        {srPools.map(
          (eToken, index) =>
            checkVisibility(eToken, mode) && (
              <SrETokenCard
                eToken={eToken}
                key={index}
                poolBadges={poolBadges}
                srEtkFilter={srEtkFilter}
                onCheck={addSrEtkFilter}
                checked={srEtkFilter.includes(eToken.address)}
              />
            )
        )}
      </Row>
      <br />
      <Container className="desktop no-margin">
        <Row className="table-header">
          <Col md={3} className="margin-auto">
            <strong>{t("Name")} </strong>
          </Col>
          <Col md={3} className="margin-auto"></Col>
          <CustomTooltip text={t("LiquidityTooltip")} placement="top">
            <Col md={2} className="margin-auto">
              <strong>{t("Liquidity")} </strong>
              <span className="fa fa-info-circle" data-toggle="tooltip" data-original-title="Info" />
            </Col>
          </CustomTooltip>
          <CustomTooltip text={t("URTooltip")} placement="top">
            <Col md={2} className="margin-auto">
              <strong>{t("Utilization Rate")} </strong>
              <span className="fa fa-info-circle" data-toggle="tooltip" data-original-title="Info" />
            </Col>
          </CustomTooltip>
          <CustomTooltip text={t("APYTooltip")} placement="top">
            <Col md={2} className="margin-auto">
              <strong>{t("APY")} </strong>
              <span className="fa fa-info-circle" data-toggle="tooltip" data-original-title="Info" />
            </Col>
          </CustomTooltip>
        </Row>
      </Container>
      {jrPools
        ?.sort((e1, e2) => getTotalSupply(e2) - getTotalSupply(e1))
        .map(
          (eToken) =>
            checkVisibility(eToken, mode) &&
            isJrOfSrFilter(eToken, srEtkFilter, state.ETokensReducer, mode) &&
            !isSrPool(eToken) && (
              <React.Fragment key={eToken.address}>
                <ETokenRowDesktop idx={eToken.address + "-Desktop"} eToken={eToken} poolBadges={poolBadges} />
                <ETokenRowMobile idx={eToken.address + "-Mobile"} eToken={eToken} poolBadges={poolBadges} />
              </React.Fragment>
            )
        )}
    </>
  );
};

ETokens.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(ETokens);
