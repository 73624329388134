import { createSelector } from "reselect";
import { getProvider } from "../../providers";

const getAddress = (state) => state.address;
const getProviderId = (state) => state.providerId;
const getChainId = (state) => state.chainId;

export const selectUserAddress = createSelector([getAddress], (address) => address);

export const selectUserAddressShort = createSelector([getAddress], (address) =>
  address ? address.substring(0, 4) + "..." + address.slice(-4) : "noaddress"
);

export const selectProvider = createSelector(getProviderId, (providerId) => getProvider(providerId));

export const selectChainId = createSelector([getChainId], (chain) => chain);
