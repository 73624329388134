import React from "react";
import { Area, AreaChart, ResponsiveContainer } from "recharts";

const LineMinichart = ({ graphData, dataKey, color }) => {
  let data = [];
  if (graphData && graphData.state === "LOADED" && graphData.value.length > 10) {
    data = graphData.value || [];
  }

  return (
    <div style={{ width: "100px", height: "50px" }}>
      <ResponsiveContainer width={100} height={"100%"}>
        <AreaChart width={500} height={200} data={data}>
          <Area type="monotone" dataKey={dataKey} fill={color} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineMinichart;
