import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { NumericFormat } from "react-number-format";
import rmIcon from "../../assets/images/RM_color.svg";
import colors from "../../colors";
import CustomPieChart from "../Charts/PieChart";

const BreakdownSection = ({ data, title, selectTab, samePage = false }) => {
  return (
    <>
      {data.length > 1 && (
        <Row>
          <Col md={12}>
            <Card className="etk-card-detail-inside">
              <hr style={{ width: "25%", color: colors.primaryDark }} />
              <h4>{title}</h4>
            </Card>
          </Col>
          <Col md={4}>
            {data.map((item, index) => (
              <Card key={index} className="etk-card-detail-inside breakdown-item">
                {samePage && (
                  <a href="#tabs" style={{ cursor: "pointer" }} onClick={() => selectTab(item.address)}>
                    <h2 className={`font-size-${24 - 2 * index}`}>
                      <span className="logo-sm">
                        <img src={rmIcon} alt="" height="35" />
                      </span>{" "}
                      {item.name}
                    </h2>
                  </a>
                )}
                {!samePage && (
                  <Link to={item.address ? `/portfolios/${item.address}` : "/portfolios"}>
                    <h2 className={`font-size-${24 - 2 * index}`}>
                      <span className="logo-sm">
                        <img src={rmIcon} alt="" height="35" />
                      </span>{" "}
                      {item.name}
                    </h2>
                  </Link>
                )}
                <div className={`colored font-size-${24 - 2 * index}`}>
                  <NumericFormat suffix={"%"} decimalScale={2} value={item.value} displayType="text" />
                </div>
              </Card>
            ))}
          </Col>
          <Col md={8} style={{ marginTop: "auto" }}>
            <CustomPieChart data={data} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default BreakdownSection;
