import usFlag from "../assets/images/flags/us.jpg";
import spain from "../assets/images/flags/spain.jpg";
// import italy from "../assets/images/flags/italy.jpg";

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  sp: {
    label: "Spanish",
    flag: spain,
  },
  // it: {
  //   label: "Italian",
  //   flag: italy,
  // },
};

export default languages;
