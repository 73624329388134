import { createSelector } from "reselect";
import _ from "lodash";

const getRiskList = (state) => state.risks;
const getLoadingState = (state) => state.loadingState;
const getSelectedRisk = (state) => state.selectedRisk;

// When exists return an index >= 0
// When NOT exists returns -1
// When I don't know yet because risks not loaded returns undefined
export const selectCurrentExists = createSelector(
  [getRiskList, getSelectedRisk, getLoadingState],
  (risks, current, loadingState) => {
    if (loadingState !== "LOADED") return undefined;
    return _.findIndex(risks, (rk) => rk.address === current);
  }
);

export const selectCurrent = createSelector([getRiskList, getSelectedRisk], (risks, current) => {
  return _.find(risks, (rk) => rk.address === current);
});

export const selectCurrentAddr = createSelector(getSelectedRisk, (current) => current);

export const selectCurrentIsReady = createSelector([selectCurrent], (current) => {
  return current !== undefined && current.data !== undefined;
});

export const selectAllReady = createSelector([getRiskList, getLoadingState], (risks, loadingState) => {
  let rk = _.find(risks, (rk) => rk.data === undefined);
  return risks.length > 0 && loadingState === "LOADED" && !rk;
});

export const selectRiskNames = createSelector([getRiskList], (risks) => {
  let dict = {};
  risks.forEach((risk) => {
    dict[risk.address] = risk.name;
  });
  return dict;
});
