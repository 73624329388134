import React from "react";
import { Row, Col } from "react-bootstrap";
import BlurredNumber from "./Blurred/BlurredNumber";
import BlurredAddress from "./Blurred/BlurredAddress";

const Parameter = ({ name, value, suffix, percentage, options, isString = false }) => {
  return (
    <Row>
      <Col md={8} xs={8}>
        <p>
          <strong>{name}</strong>
        </p>
      </Col>
      <Col md={4} xs={4} className="params-value">
        {isString ? (
          <BlurredAddress str={value} />
        ) : (
          <BlurredNumber number={value} suffix={suffix} percentage={percentage} options={options} />
        )}
      </Col>
    </Row>
  );
};

export default Parameter;
