import { select } from "redux-saga/effects";
import { call, put, takeEvery } from "redux-saga/effects";
import { selectCurrent } from "./selectors";

// Risks Redux States
import { GET_RISKS_MODULES, GET_RISKS_MODULES_SUCCESS, GET_RISKS_MODULES_FAIL, SET_SELECTED_RISK } from "./actionTypes";

import { getRisks } from "../../helpers/api_calls";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* fetchRisks({ retry }) {
  try {
    const response = yield call(getRisks);
    yield put({ type: GET_RISKS_MODULES_SUCCESS, payload: response.data });
  } catch (error) {
    delay(100);
    if (!retry || retry < 10) {
      yield put({ type: GET_RISKS_MODULES, retry: (retry || 0) + 1 });
    } else {
      yield put({ type: GET_RISKS_MODULES_FAIL, payload: error.message });
    }
  }
}

export function* updateSelectedRiskData() {
  const state = yield select((state) => state.RisksReducer);
  if (state.loadingState !== "LOADED" || selectCurrent(state) === undefined) {
    yield put({ type: GET_RISKS_MODULES });
  }
}

export function* risksSaga() {
  yield takeEvery(GET_RISKS_MODULES, fetchRisks);
  yield takeEvery(SET_SELECTED_RISK, updateSelectedRiskData);
}

export default risksSaga;
