import { all, fork } from "redux-saga/effects";

//public
import LayoutSaga from "./layout/saga";
import eTokensSaga from "./eTokens/saga";
import risksSaga from "./risks/saga";
import pasSaga from "./premiumsAccounts/saga";
import userSaga from "./user/saga";
import apiSaga from "./api/saga";
import settingsSaga from "./settings/saga";

import {
  getEncodedCall,
  getContract,
  getAbiName,
  getABI,
  getFormatter,
  getSignerContract,
  getTxReceiptStatus,
} from "../contractRegistry";
import { selectChainId, selectProvider, selectUserAddress } from "./user/selectors";
import { ethereumSaga, initializeEthereumStore } from "ethereum-store/src/package-index";
import { chain } from "../config";

initializeEthereumStore({
  getEncodedCall,
  getContract,
  getAbiName,
  getABI,
  getFormatter,
  getSignerContract,
  getTxReceiptStatus,
  selectUserAddress,
  selectChainId,
  selectProvider,
  chain,
});

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(eTokensSaga),
    fork(risksSaga),
    fork(pasSaga),
    fork(userSaga),
    fork(ethereumSaga),
    fork(apiSaga),
    fork(settingsSaga),
  ]);
}
