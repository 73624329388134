import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
// Quadrata Passport NFT Video Component
import { Passport } from "@quadrata/core-react";
import "@quadrata/core-react/lib/cjs/quadrata-ui.min.css";
import "./style.scss";

const PassportModal = ({ t, show, onHide, onClick, tokenId }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("You already have your passport!")}</Modal.Title>
      </Modal.Header>
      <Passport tokenId={tokenId} />
      <div className="align-items-center margin-auto padding-top-bottom-20">
        <Button className="btn btn-primary btn-lg " variant="secondary" onClick={onClick}>
          {t("Sign In")}
        </Button>
      </div>
    </Modal>
  );
};

PassportModal.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(PassportModal);
