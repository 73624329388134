import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Container, Row, Col } from "react-bootstrap";
import CustomTooltip from "../../CommonForBoth/CustomTooltip";
import RiskRowDesktop from "./RiskRowDesktop";
import RiskRowMobile from "./RiskRowMobile";
import Loader from "react-js-loader";
/* Helpers */
import { checkVisibility } from "../../../helpers/ensuro_helper";
/* i18n */
import { withTranslation } from "react-i18next";
import "./../style.scss";

const rmBadges = [];

const Risks = ({ t, risks, state, mode }) => {
  return (
    <Container className="no-margin">
      {state.loadingState !== "LOADED" || !risks.length ? (
        <div className="loader">
          <Loader
            type="spinner-default"
            color="#5B72E7"
            height={50}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      ) : (
        <>
          <Container className="desktop no-margin">
            <Row className="table-header">
              <Col md={2}>
                <strong>{t("Name")} </strong>
              </Col>
              <CustomTooltip text={t("ActivePoliciesTooltip")} placement="top">
                <Col md={2}>
                  <strong>{t("Active Policies")} </strong>
                  <span className="fa fa-info-circle" data-toggle="tooltip" data-original-title="Info" />
                </Col>
              </CustomTooltip>
              <CustomTooltip text={t("GWPTooltip")} placement="top">
                <Col md={1}>
                  <strong>{t("GWP")} </strong>
                  <span className="fa fa-info-circle" data-toggle="tooltip" data-original-title="Info" />
                </Col>
              </CustomTooltip>
              <CustomTooltip text={t("JRRocTooltip")} placement="top">
                <Col md={2}>
                  <strong>{t("JR RoC")} </strong>
                  <span className="fa fa-info-circle" data-toggle="tooltip" data-original-title="Info" />
                </Col>
              </CustomTooltip>
              <CustomTooltip text={t("SRRocTooltip")} placement="top">
                <Col md={2}>
                  <strong>{t("SR RoC")} </strong>
                  <span className="fa fa-info-circle" data-toggle="tooltip" data-original-title="Info" />
                </Col>
              </CustomTooltip>
              <CustomTooltip text={t("SCRTooltip")} placement="top">
                <Col md={1}>
                  <strong>{t("SCR")} </strong>
                  <span className="fa fa-info-circle" data-toggle="tooltip" data-original-title="Info" />
                </Col>
              </CustomTooltip>
              <CustomTooltip text={t("SurplusTooltip")} placement="top">
                <Col md={2}>
                  <strong>{t("Surplus/Deficit")} </strong>
                  <span className="fa fa-info-circle" data-toggle="tooltip" data-original-title="Info" />
                </Col>
              </CustomTooltip>
            </Row>
          </Container>
          {risks.map(
            (risk) =>
              checkVisibility(risk, mode) && (
                <React.Fragment key={risk.address}>
                  <RiskRowDesktop idx={risk.address + "-Desktop"} risk={risk} rmBadges={rmBadges} />
                  <RiskRowMobile idx={risk.address + "-Mobile"} risk={risk} rmBadges={rmBadges} />
                </React.Fragment>
              )
          )}
        </>
      )}
    </Container>
  );
};

Risks.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Risks);
