import React from "react";
import Loader from "react-js-loader";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { withTranslation } from "react-i18next";
import colors from "../../colors";
import moment from "moment";
import "./style.scss";

const MultiBarChart = ({ data, xKey, xLabel, yKeys, yLabel, CustomTooltip }) => {
  let fullData = data.value || [];

  return (
    <>
      {data.state !== "LOADED" ? (
        <div className="page-content loader">
          <Loader type="spinner-default" height={50} width={100} />
        </div>
      ) : (
        <div className="highlight-bar-charts" style={{ userSelect: "none", width: "95%" }}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width={800}
              height={300}
              data={fullData}
              stackOffset="sign"
              barGap={0}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={xKey}
                tickFormatter={(dateStr) => moment(dateStr).format("ll")}
                label={{ value: xLabel, position: "insideCenter", dy: 15 }}
              />
              <YAxis
                label={{
                  value: yLabel,
                  fill: colors.primaryDark,
                  angle: -90,
                  position: "insideLeft",
                  dy: 40,
                  dx: -15,
                }}
              />
              <YAxis yAxisId="right" orientation="right" domain={["auto", "auto"]} />
              <Tooltip content={<CustomTooltip />} />

              <ReferenceLine y={0} stroke="#000" />
              <Bar dataKey={yKeys[0]} fill={colors.green} stackId="stack" />
              <Bar dataKey={yKeys[1]} fill={colors.pink} stackId="stack" />
              <Bar dataKey={yKeys[2]}>
                {fullData.map((entry, index) => (
                  <React.Fragment key={index}>
                    <Cell fill={entry?.value?.total > 0 ? colors.primaryLight : colors.primaryDark} />
                  </React.Fragment>
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default withTranslation()(MultiBarChart);
