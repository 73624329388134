import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Modal, Row, Col } from "react-bootstrap";
/* Selectors */
import { selectCurrent } from "../../store/eTokens/selectors";
import { selectProvider } from "../../store/user/selectors";
/* i18n and Config */
import { chain, currency } from "../../config";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./style.scss";

const TxModal = ({ t, show, onHide, userState, etkAddress, etkSymbol, tx }) => {
  const addToken = async () => {
    let provider = selectProvider(userState);
    try {
      await provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: etkAddress,
            symbol: etkSymbol,
            decimals: currency.decimals,
          },
        },
      });
    } catch (addError) {
      throw addError;
    }
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Transaction detail")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <p>{t("Type")}:</p>
          </Col>
          <Col md={6}>
            <p>{tx.method.toUpperCase()}</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <p>{t("Tx Hash")}:</p>
          </Col>
          <Col md={6}>{tx.txHash.substring(0, 6) + "..." + tx.txHash.slice(-6)}</Col>
        </Row>
        <Row>
          <Col md={6}>
            <p>{t("Status")}:</p>
          </Col>
          {tx.state === "MINED" ? (
            <Col md={6}>
              <p style={{ color: "green" }}>
                <i className="uil uil-check" /> {tx.state}
              </p>
            </Col>
          ) : tx.state === "QUEUED" ? (
            <Col md={6}>
              <p style={{ color: "blue" }}>
                <i className="uil uil-clock" /> {tx.state}
              </p>
            </Col>
          ) : (
            <Col md={6}>
              <p style={{ color: "blue" }}>{tx.state}</p>
            </Col>
          )}
        </Row>
        <Row>
          {tx.state === "EXPIRED" ? <p>{t("Please check the state of your transaction in block explorer")}</p> : <></>}
        </Row>
        <Row>
          <Col>
            <a target="_blank" rel="noopener noreferrer" href={chain.scan + "tx/" + tx.txHash}>
              <i className="uil uil-external-link-alt" />
              {t(" View on block explorer")}
            </a>
          </Col>
        </Row>

        <Row>
          <Col>
            <button onClick={addToken} className="font-size-15 without-padding btn" style={{ color: "black" }}>
              <i className="uil uil-wallet " />
              {t(" Add eToken to your wallet")}
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

TxModal.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const userState = state.UserReducer;
  const etk = selectCurrent(state.ETokensReducer);
  const etkSymbol = etk.symbol;
  const etkAddress = etk.address;

  return { userState, etkAddress, etkSymbol };
};

export default connect(mapStateToProps)(withTranslation()(TxModal));
