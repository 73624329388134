import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomTooltip = ({ placement, text, children }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id="tooltip-2" className="in p-without-padding">
          {text}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
