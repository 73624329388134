import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { Card } from "semantic-ui-react";
//i18n
import { withTranslation } from "react-i18next";

const DescriptionCard = ({ t, description }) => {
  return (
    <Card className="description-card">
      <ReactMarkdown>{description}</ReactMarkdown>
    </Card>
  );
};

DescriptionCard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(DescriptionCard);
