import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import BlurredNumber from "../../CommonForBoth/Blurred/BlurredNumber";
import CustomTooltip from "../../CommonForBoth/CustomTooltip";
import Badges from "../../CommonForBoth/Badges";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "semantic-ui-react";
/* Store */
import { selectAPICallMultiple } from "../../../store/api/selectors";
import { selectEthCallMultiple } from "ethereum-store/src/store/ethereum/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
/* Helpers */
import { refreshAPICalls } from "../../../helpers/api_store_helper";
import defaultPALogo from "../../../assets/images/PA_color.svg";
import defaultRMLogo from "../../../assets/images/risks-symbol.png";
import "./../style.scss";

const makeEthCalls = function (paAddress) {
  return [{ address: paAddress, abi: "PremiumsAccount", method: "activePurePremiums", args: [] }];
};

const makeAPICalls = (paAddress) => {
  return [
    { apiName: "paActivePolicies", args: [paAddress] },
    { apiName: "rmByPremiumsAccount", args: [paAddress] },
    { apiName: "paMaturedSurplus", args: [paAddress] },
  ];
};

const PARowDesktop = ({ t, pa, idx, activePurePremiums, activePolicies, rms, maturedSurplus, paBadges }) => {
  const navigate = useNavigate();
  const mounted = useRef(false);
  let dispatch = useDispatch();
  let tags = pa.custom_data?.tags || [];

  const handleCardClick = (address) => {
    navigate("/portfolios/" + address);
  };

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "paDesktop" + pa.address,
      componentEthCalls: makeEthCalls(pa.address),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "paDesktop" + pa.address });
      mounted.current = false;
    };
  }, [dispatch, pa.address]);

  useEffect(() => {
    return refreshAPICalls(dispatch, makeAPICalls(pa.address));
  }, [pa.address, dispatch]);

  return (
    <Container className="desktop no-margin">
      <Card className="card-row card-hover primary-colored" onClick={() => handleCardClick(pa.address)} key={idx}>
        <Container className="no-margin">
          <Row>
            <Col md={3} className="font-size-16 name-with-logo margin-auto">
              <span className="logo-sm with-20-padding">
                <img src={pa.logo || defaultPALogo} alt="" height="30" />
              </span>{" "}
              {pa.name} <Badges tags={tags} badgesData={paBadges || []} />
            </Col>
            <Col md={1}>
              <Card className="card-inside primary-colored">
                <BlurredNumber number={activePolicies} options={{ notation: "standard" }} />
              </Card>
            </Col>
            <Col md={3}>
              <Card className="card-inside primary-colored">
                <BlurredNumber
                  number={activePurePremiums}
                  options={{ maximumFractionDigits: 2, notation: "compact" }}
                />
              </Card>
            </Col>
            <Col md={2}>
              <Card className="card-inside" style={{ color: Math.sign(maturedSurplus.value) < 0 ? "red" : "green" }}>
                <BlurredNumber number={maturedSurplus} options={{ maximumFractionDigits: 2, notation: "compact" }} />
              </Card>
            </Col>
            <Col md={3}>
              <Card className="card-inside">
                <span className="logo-sm with-20-padding">
                  {rms &&
                    rms.value &&
                    rms.value.length > 0 &&
                    rms.value.map((rm, idx) => (
                      <React.Fragment key={idx}>
                        <CustomTooltip text={rm.name} placement="top">
                          <span>
                            <img className="logo-sm" src={rm.logo || defaultRMLogo} alt="" height="30" key={idx} />{" "}
                          </span>
                        </CustomTooltip>
                      </React.Fragment>
                    ))}
                </span>
              </Card>
            </Col>
          </Row>
        </Container>
      </Card>
    </Container>
  );
};

PARowDesktop.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const paAddress = ownProps.pa.address;
  const [activePurePremiums] = selectEthCallMultiple(state.EthereumReducer, makeEthCalls(paAddress));
  const [activePolicies, rms, maturedSurplus] = selectAPICallMultiple(state.APIReducer, makeAPICalls(paAddress));
  return { activePurePremiums, activePolicies, rms, maturedSurplus };
};

export default connect(mapStateToProps)(withTranslation()(PARowDesktop));
