import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Button } from "react-bootstrap";
/* i18n */
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { chain } from "../../config";
import "./style.scss";

const TxButton = ({ t, chainId, onClick, disabled, text }) => {
  return (
    <Button className="btn btn-primary btn-lg" onClick={onClick} disabled={chainId !== chain.id || disabled}>
      {chainId !== chain.id ? t("Wrong Network") : text}
    </Button>
  );
};

TxButton.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const userState = state.UserReducer;
  return { userState };
};

export default connect(mapStateToProps)(withTranslation()(TxButton));
