import React, { useState } from "react";
import PropTypes from "prop-types";
/* Components */
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { SiweMessage } from "@ensuro/siwe";
import KYCAlerts from "./KYCAlerts";
/* Helpers and Selectors */
import { getExpirationDate } from "../../helpers/local_storage_helper";
import { selectUserAddress } from "../../store/user/selectors";
import { selectCurrent } from "../../store/eTokens/selectors";
/* i18n and Config */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import countries from "../../common/countries";
import { chain } from "../../config";
import "./style.scss";

const Web3 = require("web3");

const occupations = [
  "Student",
  "Engineer",
  "Teacher",
  "Healthcare Professional",
  "Artist",
  "Business Owner",
  "Lawyer",
  "Accountant",
  "Programmer",
  "Entrepreneur",
  "Sales Representative",
  "Consultant",
  "Government Worker",
  "Retiree",
  "Finance",
  "Actuary",
  "Other",
];

const SignInModal = ({ t, show, onHide, state, user, userAddress, etkWhitelist }) => {
  let dispatch = useDispatch();
  const [email, setUserMail] = useState("");
  const [country, setCountry] = useState(null);
  const [occupation, setOccupation] = useState(null);

  const getStatement = () => {
    let s = "I accept the Ensuro Terms of Service: https://ensuro.co/Ensuro_ToS.pdf.";
    s += " Email address: " + email;
    s += ". Country: " + country?.name;
    s += ". Occupation: " + occupation;
    return s;
  };

  const message =
    userAddress &&
    new SiweMessage({
      domain: window.location.host,
      address: userAddress,
      statement: getStatement(),
      uri: window.location.origin,
      version: "1",
      chainId: chain.id,
      expirationTime: getExpirationDate(), // check if we need to add this
    });

  const signMessage = async () => {
    try {
      const m = message.prepareMessage();
      dispatch({
        type: "ETH_SIWE_SIGN",
        message: m,
        userAddress: userAddress,
        email: email,
        country: country.name,
        occupation: occupation,
        whitelist: etkWhitelist,
      });
    } catch (addError) {
      throw addError;
    }
  };

  const invalidMail = () => {
    let valid = email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return !valid;
  };

  const hideModal = () => {
    setUserMail("");
    setCountry(null);
    setOccupation(null);
    onHide();
  };

  return (
    <Modal show={show} centered onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("KYC")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state === "SIGN_IN" && (
          <>
            <Alert variant="info">
              {t(
                "This information is required by Ensuro to comply with regulatory requirements. Ensuro will store this information."
              )}
            </Alert>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>{t("Email address")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  isInvalid={email.length > 0 && invalidMail()}
                  value={email}
                  onChange={(e) => setUserMail(e.target.value || "")}
                />
                <Form.Control.Feedback type="invalid">{t("Please provide a valid mail.")}</Form.Control.Feedback>
                <br />
                <Form.Label>{t("Country of Residence")}</Form.Label>
                <Typeahead
                  id="country-selection"
                  labelKey="name"
                  onChange={(selections) => setCountry(...selections)}
                  options={countries}
                  placeholder={t("Select your country")}
                />
                {/* <CountrySelect value={country} onChange={setCountry} placeholder={t("Select your country")} /> */}
                <br />
                <Form.Label>{t("Occupation")}</Form.Label>
                <Form.Select aria-label={t("Select your occupation")} onChange={(e) => setOccupation(e.target.value)}>
                  <option value={null}>{t("Select your occupation")}</option>
                  {occupations.map((o) => (
                    <option value={o} key={o}>
                      {o}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </>
        )}
        <KYCAlerts state={state} />
      </Modal.Body>
      <Modal.Footer>
        {state === "SIGN_IN" && (
          <Button
            className="align-end"
            variant="primary"
            onClick={signMessage}
            disabled={
              !email.length > 0 ||
              invalidMail() ||
              !country?.name.length > 0 ||
              !occupation?.length > 0 ||
              (user && !user.canTransact)
            }
          >
            {t("Sign In")}
          </Button>
        )}
      </Modal.Footer>
      {user && !user.canTransact && (
        <p className="read-only">{t("Read-only mode. Connect to a wallet to perform transactions.")}</p>
      )}
    </Modal>
  );
};

SignInModal.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const user = state.UserReducer;
  let userAddress = selectUserAddress(user);
  const etk = selectCurrent(state.ETokensReducer);
  const etkWhitelist = etk && etk.whitelist;

  userAddress = userAddress ? Web3.utils.toChecksumAddress(userAddress) : undefined;
  return { user, userAddress, etkWhitelist };
};

export default connect(mapStateToProps)(withTranslation()(SignInModal));
