// PA Redux States
import {
  GET_PREMIUMS_ACCOUNTS,
  GET_PREMIUMS_ACCOUNTS_SUCCESS,
  GET_PREMIUMS_ACCOUNTS_FAIL,
  SET_SELECTED_PA,
} from "./actionTypes";

const INIT_STATE = {
  pas: [],
  loadingState: "NOT_LOADED",
  // NOT_LOADED -> Loading -> LOADED
  //                       \-> ERROR
  selectedPA: "",
  error: {
    message: "",
  },
};

const PAReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PREMIUMS_ACCOUNTS:
      state = { ...state, loadingState: "LOADING" };
      break;

    case GET_PREMIUMS_ACCOUNTS_SUCCESS:
      state = { ...state, pas: action.payload, loadingState: "LOADED" };
      break;

    case GET_PREMIUMS_ACCOUNTS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingState: "ERROR",
      };
      break;

    case SET_SELECTED_PA:
      state = {
        ...state,
        selectedPA: action.address,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PAReducer;
