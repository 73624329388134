import React from "react";
import Loader from "react-js-loader";
import colors from "../../colors";
import { withTranslation } from "react-i18next";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import moment from "moment";
import "./style.scss";

const MultiLineChart = ({
  xKey,
  xLabel,
  leftData,
  leftKey,
  leftLabel,
  rightData,
  rightKey,
  rightLabel,
  CustomTooltip,
}) => {
  let rightAxisData = rightData?.value || [];
  let leftAxisData = leftData?.value || [];
  let fullData = [];

  if (rightAxisData[0] && leftAxisData[0]) {
    if (leftAxisData[0].date < rightAxisData[0].date) {
      fullData = leftAxisData.map((row) => {
        let d = rightAxisData.find((r) => r.date === row.date);
        return { date: row.date, [rightKey]: d ? d[rightKey] : 0, [leftKey]: row[leftKey] };
      });
    } else {
      fullData = rightAxisData.map((row) => {
        let d = leftAxisData.find((r) => r.date === row.date);
        return {
          date: row.date,
          [rightKey]: row[rightKey],
          [leftKey]: d ? d[leftKey] : 0,
        };
      });
    }
  }

  return (
    <>
      {rightData.state !== "LOADED" || leftData.state !== "LOADED" ? (
        <div className="page-content loader">
          <Loader type="spinner-default" height={50} width={100} timeout={1000} />
        </div>
      ) : (
        <div className="highlight-bar-charts" style={{ userSelect: "none", width: "95%" }}>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={500}
              height={300}
              data={fullData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={xKey}
                tickFormatter={(timeStr) => moment(timeStr).format("ll")}
                label={{ value: xLabel, position: "insideCenter", dy: 15 }}
              />

              <YAxis
                allowDataOverflow
                yAxisId="1"
                tickCount={7}
                label={{
                  value: leftLabel,
                  fill: colors.primaryDark,
                  angle: -90,
                  position: "insideLeft",
                  dy: 60,
                  dx: -15,
                }}
              />
              <YAxis
                orientation="right"
                allowDataOverflow
                yAxisId="2"
                tickCount={7}
                domain={["auto", "auto"]}
                label={{
                  value: rightLabel,
                  fill: colors.primaryLight,
                  angle: -270,
                  position: "insideRight",
                  dy: 40,
                  dx: 15,
                }}
              />
              <Tooltip content={<CustomTooltip />} cursor={{ fill: "#000000" }} />

              <Line
                yAxisId="1"
                type="monotone"
                dataKey={leftKey}
                stroke={colors.primaryDark}
                strokeWidth="3"
                dot={false}
                animationDuration={300}
              />
              <Line
                yAxisId="2"
                type="monotone"
                dataKey={rightKey}
                stroke={colors.primaryLight}
                strokeWidth="3"
                dot={false}
                animationDuration={300}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default withTranslation()(MultiLineChart);
