import { select } from "redux-saga/effects";
import { call, put, takeEvery } from "redux-saga/effects";
import { selectCurrent } from "./selectors";

// ETokens Redux States
import { GET_ETOKENS, GET_ETOKENS_SUCCESS, GET_ETOKENS_FAIL, SET_SELECTED_ETOKEN } from "./actionTypes";

import { getETokens } from "../../helpers/api_calls";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* fetchETokens({ retry }) {
  try {
    const response = yield call(getETokens);
    yield put({ type: GET_ETOKENS_SUCCESS, payload: response.data });
  } catch (error) {
    delay(100);
    if (!retry || retry < 10) {
      yield put({ type: GET_ETOKENS, retry: (retry || 0) + 1 });
    } else {
      yield put({ type: GET_ETOKENS_FAIL, payload: error.message });
    }
  }
}

export function* updateSelectedETokenData() {
  const state = yield select((state) => state.ETokensReducer);
  if (state.loadingState !== "LOADED" || selectCurrent(state) === undefined) {
    yield put({ type: GET_ETOKENS });
  }
}

export function* eTokensSaga() {
  yield takeEvery(GET_ETOKENS, fetchETokens);
  yield takeEvery(SET_SELECTED_ETOKEN, updateSelectedETokenData);
}

export default eTokensSaga;
