import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
// import { Row, Col, BreadcrumbItem } from "reactstrap";
import { Row, Col } from "reactstrap";

const Breadcrumb = (props) => {
  return (
    <Row>
      {props.logo ? (
        <Col className="col-12" style={{ fontSize: "28px" }}>
          <span className="logo-sm">
            <img src={props.logo} alt="" height="35" />
          </span>{" "}
          {props.breadcrumbItem}
        </Col>
      ) : (
        <Col className="col-12" style={{ fontSize: "28px" }}>
          {props.breadcrumbItem}
        </Col>
      )}
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
