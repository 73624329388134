import { alchemy, chain } from "./config";
const { ethers } = require("ethers");

export const roProvider =
  alchemy.network !== undefined
    ? new ethers.AlchemyProvider(alchemy.network, alchemy.apiKey)
    : new ethers.JsonRpcProvider(chain.rpc);

export const providers = {};

export function registerProvider(provider) {
  providers[1] = provider;
  return 1;
}

export function getProvider(providerId) {
  return providers[providerId];
}
