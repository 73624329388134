import { get } from "./api_helper";
import { overrides } from "../config";
import { toDecimal } from "./number_format_helper";
import { isEmpty } from "lodash";

export const getFieldSumByChar = (field) => {
  let sum = 0;
  for (let i = 0; i < field.length; i++) {
    sum += field.charCodeAt(i);
  }
  return sum;
};

export const convertResponse = (response, key) => {
  response.forEach((r) => {
    if (r[key]) {
      r[key] = toDecimal(r[key]);
    }
  });
  return response;
};

export const convertCashflow = (response) => {
  response.forEach((r) => {
    if (r.value && r.value.payout) {
      r.value.payout *= -1;
    }
  });
  return response;
};

// get eTokens
/* deprecated */
export const getETokens = async () => get("/etokens");

// get risks
/* deprecated */
export const getRisks = async () => get("/riskmodules/");

// get premiums accounts
export const getPremiumsAccounts = async () => get("/premiumsaccounts/");

export const setValueAsPercentageWithMax = (response, key, maxValue) => {
  response.forEach((r) => {
    if (r.value && r.value.hasOwnProperty(key)) {
      r.value[key] = maxValue ? Math.min(r.value[key] * 100, maxValue) : r.value[key] * 100;
    } else if (r.hasOwnProperty(key)) {
      r[key] = maxValue ? Math.min(r[key] * 100, maxValue) : r[key] * 100;
    }
  });
  return response;
};

export const addDaysParams = (days_from, days_to, days = undefined) => {
  if (days_to && days_from && days) return { days, days_from, days_to };
  if (days_from && days) return { days, days_from };
  if (days_to && days) return { days, days_to };
  if (days_to && days_from) return { days_from, days_to };
  if (days_from) return { days_from };
  if (days_to) return { days_to };
  if (days) return { days };
  return {};
};

export const addOverrides = (address) => (overrides.includes(address) ? { override: true } : {});

export const makeQueryParams = (...args) => {
  const merged = Object.assign({}, ...args);
  if (!isEmpty(merged)) return `?${new URLSearchParams(merged)}`;
  return "";
};
