import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "semantic-ui-react";
/* Components */
import DescriptionCard from "../CommonForBoth/DescriptionCard";
import CashflowCharts from "../CommonForBoth/CashflowCharts";
import MoreInfoDocs from "../CommonForBoth/MoreInfoDocs";
import MiniWidget from "../CommonForBoth/MiniWidget";
import DaysFilter from "../CommonForBoth/DaysFilter";
import LineMinichart from "../Charts/LineMinichart";
import BarMinichart from "../Charts/BarMinichart";
import RiskParams from "./RiskParams";
/* Selectors */
import { selectEthCallMultiple } from "ethereum-store/src/store/ethereum/selectors";
import { selectAPICallMultiple } from "../../store/api/selectors";
import { selectCurrent } from "../../store/risks/selectors";
/* Helpers */
import { getEtkWithURL, getPAWithURL, getRiskParams } from "../../helpers/ensuro_helper";
import { dispatchAPICalls, refreshAPICalls } from "../../helpers/api_store_helper";
/* Store */
import * as etkActionTypes from "../../store/eTokens/actionTypes";
import * as actionTypes from "../../store/premiumsAccounts/actionTypes";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { minicharts } from "../../config";
import colors from "../../colors";
import "./risk-style.scss";

const makeEthCalls = function (rmAddress) {
  return [
    { address: rmAddress, abi: "RiskModule", method: "params", args: [] },
    { address: rmAddress, abi: "RiskModule", method: "exposureLimit", args: [] },
    { address: rmAddress, abi: "RiskModule", method: "maxPayoutPerPolicy", args: [] },
    { address: rmAddress, abi: "RiskModule", method: "maxDuration", args: [] },
  ];
};

const makeAPICalls = (rmAddress) => {
  return [
    { apiName: "activePolicies", args: [rmAddress] },
    { apiName: "maturedSurplus", args: [rmAddress] },
    { apiName: "gwp", args: [rmAddress] },
    { apiName: "scr", args: [rmAddress] },
  ];
};

const minichartAPICalls = (rmAddress) => {
  return [
    { apiName: "maturedSurplusHistory", args: [rmAddress, minicharts.daysFrom] },
    { apiName: "activePoliciesHistory", args: [rmAddress, minicharts.daysFrom] },
    { apiName: "scrHistory", args: [rmAddress, minicharts.daysFrom] },
    { apiName: "gwpHistory", args: [rmAddress, minicharts.daysFrom] },
  ];
};

const chartCalls = (address, daysFrom) => {
  return [
    { apiName: "activePremiums", args: [address, daysFrom] },
    { apiName: "maturedSurplusHistory", args: [address, daysFrom] },
    { apiName: "cashflow", args: [address, daysFrom] },
  ];
};

const RiskDetail = ({
  t,
  links,
  rm,
  rmAddress,
  rmParams,
  jrEtk,
  srEtk,
  exposureLimit,
  maxPayout,
  maxDuration,
  paInRM,
  activePolicies,
  gwp,
  maturedSurplus,
  maturedSurplusHistory,
  policiesHistory,
  scrHistory,
  gwpHistory,
  scr,
}) => {
  let dispatch = useDispatch();
  const mounted = useRef(false);
  const [days, setDays] = useState(90); // Default 3M

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "riskDetail",
      componentEthCalls: makeEthCalls(rmAddress),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "riskDetail" });
      mounted.current = false;
    };
  }, [dispatch, rmAddress]);

  useEffect(() => {
    return refreshAPICalls(dispatch, makeAPICalls(rmAddress));
  }, [rmAddress, dispatch]);

  useEffect(() => {
    return dispatchAPICalls(dispatch, minichartAPICalls(rmAddress));
  }, [rmAddress, dispatch]);

  useEffect(() => {
    dispatch({ type: etkActionTypes.GET_ETOKENS });
    dispatch({ type: actionTypes.GET_PREMIUMS_ACCOUNTS });
  }, [dispatch]);

  return (
    <Container className="no-margin">
      <Row>
        <MiniWidget
          data={{
            title: t("Active Policies"),
            label: t("Active Policies"),
            value: activePolicies,
            options: { notation: "standard" },
          }}
          Component={LineMinichart}
          graph={policiesHistory}
          dataKey="value"
          color={colors.primaryLight}
          address={rmAddress}
          apiCall={"activePoliciesHistory"}
        />
        <MiniWidget
          data={{ title: t("GWP"), value: gwp, label: "GWP [$]", options: { notation: "compact" }, prefix: "$" }}
          Component={BarMinichart}
          graph={gwpHistory}
          dataKey="gwp"
          color={colors.skyBlue}
          address={rmAddress}
          apiCall={"gwpHistory"}
        />
        <MiniWidget
          data={{ title: t("SCR"), value: scr, label: "SCR [$]", options: { notation: "compact" }, prefix: "$" }}
          Component={LineMinichart}
          graph={scrHistory}
          dataKey="scr"
          color={colors.pink}
          address={rmAddress}
          apiCall={"scrHistory"}
        />
        <MiniWidget
          data={{
            title: t("Surplus/Deficit"),
            label: "Surplus [$]",
            value: maturedSurplus,
            options: { notation: "compact" },
            prefix: "$",
          }}
          Component={LineMinichart}
          graph={maturedSurplusHistory}
          dataKey="matured_surplus"
          color={colors.green}
          address={rmAddress}
          apiCall={"maturedSurplusHistory"}
        />
      </Row>
      {rm.description !== "" && links && links.length > 0 && (
        <Row>
          <Col md={8}>
            <DescriptionCard description={rm.description} />
          </Col>
          {links && links.length > 0 && (
            <Col md={4}>
              <MoreInfoDocs docs={links} />
            </Col>
          )}
        </Row>
      )}
      <Row>
        <Col md={8}>
          <Card fluid>
            <br />
            <DaysFilter title={t("Cashflow")} setDays={setDays} />
            <br />
            <CashflowCharts address={rmAddress} daysFrom={days} apiCalls={chartCalls} />
          </Card>
        </Col>
        <Col md={4}>
          <RiskParams
            address={rmAddress}
            rmParams={rmParams}
            maxScrPerPolicy={maxPayout}
            maxDuration={maxDuration}
            scrLimit={exposureLimit}
            paInRM={paInRM}
            jrEtk={jrEtk}
            srEtk={srEtk}
          />
        </Col>
      </Row>
    </Container>
  );
};

RiskDetail.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const rm = selectCurrent(state.RisksReducer);
  const rmAddress = rm.address;
  const links = rm.links;
  const paInRM = getPAWithURL(state.PAReducer, rm.premiums_account);
  const jrEtk = getEtkWithURL(state.ETokensReducer, rm["jr_etk"]);
  const srEtk = getEtkWithURL(state.ETokensReducer, rm["sr_etk"]);

  const [params, exposureLimit, maxPayout, maxDuration] = selectEthCallMultiple(
    state.EthereumReducer,
    makeEthCalls(rmAddress)
  );
  const rmParams = getRiskParams(params);
  const [activePolicies, maturedSurplus, gwp, scr] = selectAPICallMultiple(state.APIReducer, makeAPICalls(rmAddress));

  const [maturedSurplusHistory, policiesHistory, scrHistory, gwpHistory] = selectAPICallMultiple(
    state.APIReducer,
    minichartAPICalls(rmAddress)
  );

  return {
    links,
    rm,
    rmAddress,
    rmParams,
    jrEtk,
    srEtk,
    exposureLimit,
    maxPayout,
    maxDuration,
    paInRM,
    activePolicies,
    maturedSurplus,
    gwp,
    maturedSurplusHistory,
    policiesHistory,
    scrHistory,
    gwpHistory,
    scr,
  };
};

export default connect(mapStateToProps)(withTranslation()(RiskDetail));
