module.exports = {
  primary: "#0055B8",
  primaryDark: "#00263D",
  primaryLight: "#8AB7E9",
  secondary: "#0066FF",
  secondaryDark: "#012376",
  secondaryLight: "#EEEEEE",
  skyBlue: "#C2E1E4",
  pink: "#EAA7FD",
  green: "#A5FF76",
};
