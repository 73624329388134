import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "semantic-ui-react";
import BlurredNumber from "../CommonForBoth/Blurred/BlurredNumber";
import BreakdownSection from "../CommonForBoth/BreakdownSection";
import DescriptionCard from "../CommonForBoth/DescriptionCard";
import MoreInfoDocs from "../CommonForBoth/MoreInfoDocs";
import MiniWidget from "../CommonForBoth/MiniWidget";
import LineMinichart from "../Charts/LineMinichart";
import UserInformation from "./UserInformation";
import DurationSection from "./DurationSection";
import StackedProgress from "./StackedProgress";
import ETokenParams from "./ETokenParams";
import LPActivity from "./LPActivity";
import Progress from "./Progress";
/* Selectors */
import { selectPANames } from "../../store/premiumsAccounts/selectors";
import { selectEthCallMultiple } from "ethereum-store/src/store/ethereum/selectors";
import { selectAPICallMultiple } from "../../store/api/selectors";
import { selectCurrent } from "../../store/eTokens/selectors";
/* Store */
import * as paActionTypes from "../../store/premiumsAccounts/actionTypes";
/* Helpers */
import { getAllPremiumsAccountsByEtk, getUtilizationRate } from "../../helpers/ensuro_helper";
import { dispatchAPICalls, refreshAPICalls } from "../../helpers/api_store_helper";
import { setPercentages } from "../../helpers/charts_helper";
/* i18n */
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { apy, minicharts } from "../../config";
import colors from "../../colors";
import "./single_eToken.scss";

const makeEthCalls = function (etkAddress) {
  return [
    { address: etkAddress, abi: "EToken", method: "totalSupply", args: [] },
    { address: etkAddress, abi: "EToken", method: "scr", args: [] },
    { address: etkAddress, abi: "EToken", method: "internalLoanInterestRate", args: [] },
    { address: etkAddress, abi: "EToken", method: "maxUtilizationRate", args: [] },
    { address: etkAddress, abi: "EToken", method: "minUtilizationRate", args: [] },
    { address: etkAddress, abi: "EToken", method: "assetManager", args: [] },
  ];
};

const makeAPICalls = (etkAddress) => {
  return [
    { apiName: "apy", args: [etkAddress, apy.range] },
    { apiName: "paScrBreakdown", args: [etkAddress] },
    { apiName: "lockup", args: [etkAddress] },
  ];
};

const minichartAPICalls = (etkAddress) => {
  return [
    { apiName: "etkScrHistory", args: [etkAddress, minicharts.daysFrom] },
    { apiName: "totalSupplyHistory", args: [etkAddress, minicharts.daysFrom] },
    { apiName: "urHistory", args: [etkAddress, minicharts.daysFrom] },
    { apiName: "apyHistory", args: [etkAddress] },
  ];
};

const ETokenDetail = ({
  t,
  etkAddress,
  etk,
  paInEtk,
  totalSupply,
  scr,
  internalLoanInterestRate,
  minUR,
  maxUR,
  assetManager,
  scrBreakdownByPercentage,
  apy,
  lockup,
  etkScrHistory,
  totalSupplyHistory,
  urHistory,
  apyHistory,
}) => {
  let dispatch = useDispatch();
  const mounted = useRef(false);
  const ur = getUtilizationRate(totalSupply, scr);

  // Initial useEffects
  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "etokenDetail",
      componentEthCalls: makeEthCalls(etkAddress),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "etokenDetail" });
      mounted.current = false;
    };
  }, [dispatch, etkAddress]);

  useEffect(() => {
    return refreshAPICalls(dispatch, makeAPICalls(etkAddress));
  }, [etkAddress, dispatch]);

  useEffect(() => {
    return dispatchAPICalls(dispatch, minichartAPICalls(etkAddress));
  }, [etkAddress, dispatch]);

  useEffect(() => {
    dispatch({ type: paActionTypes.GET_PREMIUMS_ACCOUNTS });
  }, [dispatch]);

  const am = assetManager && assetManager.value && etk.custom_data && etk.custom_data[assetManager.value];

  return (
    <Container className="no-margin">
      <Row>
        <MiniWidget
          data={{
            title: "APY",
            value: apy,
            label: "APY [%]",
            options: { maximumFractionDigits: 2, notation: "compact" },
          }}
          Component={LineMinichart}
          graph={apyHistory}
          dataKey="value.apy"
          color={colors.primaryLight}
          suffix={"%"}
          percentage={true}
          address={etkAddress}
          apiCall={"apyHistory"}
        />
        <MiniWidget
          data={{ title: t("Utilization Rate"), label: t("UR [%]"), value: { value: ur, state: ur ? "LOADED" : "" } }}
          Component={LineMinichart}
          graph={urHistory}
          dataKey="ur"
          color={colors.skyBlue}
          suffix={"%"}
          address={etkAddress}
          apiCall={"urHistory"}
        />
        <MiniWidget
          data={{ title: "SCR", value: scr, label: "SCR [$]", options: { notation: "compact" }, prefix: "$" }}
          Component={LineMinichart}
          graph={etkScrHistory}
          dataKey="value.scr"
          color={colors.pink}
          address={etkAddress}
          apiCall={"etkScrHistory"}
        />
        <MiniWidget
          data={{
            title: t("Liquidity"),
            value: totalSupply,
            label: t("Liquidity [$]"),
            options: { notation: "compact" },
            prefix: "$",
          }}
          Component={LineMinichart}
          graph={totalSupplyHistory}
          dataKey="total_supply"
          color={colors.green}
          address={etkAddress}
          apiCall={"totalSupplyHistory"}
        />
      </Row>
      {etk.description !== "" && etk.links && etk.links.length > 0 && (
        <Row>
          <Col md={8}>
            <DescriptionCard description={etk.description} />
          </Col>
          {etk.links && etk.links.length > 0 && (
            <Col md={4}>
              <MoreInfoDocs docs={etk.links} />
            </Col>
          )}
        </Row>
      )}
      <Row>
        <Col md={8}>
          <Card fluid>
            <br />
            <Row>
              <Col md={4}>
                <Card className="etk-card-detail-inside">
                  <h2>{t("Total SCR")}</h2>
                  <div className="font-size-24 colored">
                    <BlurredNumber number={scr} options={{ maximumFractionDigits: 2, notation: "compact" }} />
                  </div>
                </Card>
                <Card className="etk-card-detail-inside">
                  <h2>{t("Liquidity")}</h2>
                  <div className="font-size-24 colored">
                    <BlurredNumber number={totalSupply} options={{ maximumFractionDigits: 2, notation: "compact" }} />
                  </div>
                </Card>
              </Col>

              <Col md={8} xs={12} className="xs-centered">
                <Progress title={t("Utilization Rate")} value={ur} variant="primary" minUR={minUR} maxUR={maxUR} />
                <StackedProgress
                  title={t("Unlock Schedule")}
                  data={lockup}
                  totalSupply={totalSupply}
                  scr={scr}
                  utilizationRate={ur}
                />
              </Col>
            </Row>
            <BreakdownSection data={scrBreakdownByPercentage} title={t("Risk Partners Breakdown")} />
            <DurationSection />
          </Card>
          <LPActivity />
        </Col>
        <Col md={4}>
          <UserInformation />
          <ETokenParams
            address={etkAddress}
            internalLoanInterestRate={internalLoanInterestRate}
            minUR={minUR}
            maxUR={maxUR}
            assetManager={{ am: assetManager, text: am }}
            pas={paInEtk}
          />
        </Col>
      </Row>
    </Container>
  );
};

ETokenDetail.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const etk = selectCurrent(state.ETokensReducer);
  const etkAddress = etk.address;
  const pas = selectPANames(state.PAReducer);
  const [totalSupply, scr, internalLoanInterestRate, maxUR, minUR, assetManager] = selectEthCallMultiple(
    state.EthereumReducer,
    makeEthCalls(etkAddress)
  );

  const [apy, scrBreakdown, lockup] = selectAPICallMultiple(state.APIReducer, makeAPICalls(etkAddress));
  const [etkScrHistory, totalSupplyHistory, urHistory, apyHistory] = selectAPICallMultiple(
    state.APIReducer,
    minichartAPICalls(etkAddress)
  );
  const scrBreakdownByPercentage =
    scrBreakdown.state === "LOADED" ? setPercentages(scrBreakdown.value, pas, "scr", "pa") : [];

  const etkPAs = etk && etk.jr_for_pas.length > 0 ? etk.jr_for_pas : etk.sr_for_pas.length > 0 ? etk.sr_for_pas : [];
  const paInEtk = pas ? getAllPremiumsAccountsByEtk(etkPAs, pas) : [];

  return {
    etkAddress,
    etk,
    paInEtk,
    totalSupply,
    scr,
    internalLoanInterestRate,
    minUR,
    maxUR,
    assetManager,
    scrBreakdownByPercentage,
    apy,
    lockup,
    etkScrHistory,
    totalSupplyHistory,
    urHistory,
    apyHistory,
  };
};

export default connect(mapStateToProps)(withTranslation()(ETokenDetail));
