import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import etkIcon from "../../assets/images/etoken_white.svg";
import paIcon from "../../assets/images/PA_white.svg";

const HeaderButtons = (props) => {
  const [menu, setMenu] = useState(false);

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <React.Fragment>
      <Link className="link-button font-size-20 btn header-item waves-effect" to="/">
        <span>
          <img src={etkIcon} alt="" height="25" />
        </span>{" "}
        {props.t("Pools")}
      </Link>
      <Link className="link-button font-size-20 btn header-item waves-effect" to="/portfolios">
        <span>
          <img src={paIcon} alt="" height="20" />
        </span>{" "}
        {props.t("Risk Partners")}
      </Link>

      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block language-switch">
        <DropdownToggle className="btn header-item waves-effect font-size-20" tag="button">
          <i className="uil-plus me-2" />
          {props.t("More")}
          <div className="arrow-down"></div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-start">
          <DropdownItem>
            <a target="_blank" rel="noopener noreferrer" href={"https://docs.ensuro.co/product-docs/"}>
              <p className="font-size-16 no-margin-black">
                <i className="mdi mdi-file-document-multiple-outline me-2" /> {props.t("Docs")}
              </p>
            </a>
          </DropdownItem>
          <DropdownItem>
            <a target="_blank" rel="noopener noreferrer" href={"https://discord.gg/sePJF7RRZC"}>
              <p className="font-size-16 no-margin-black">
                <i className="mdi mdi-discord me-2" /> {props.t("Discord")}
              </p>
            </a>
          </DropdownItem>
          <DropdownItem>
            <a target="_blank" rel="noopener noreferrer" href={"https://ensuro.co"}>
              <p className="font-size-16 no-margin-black">
                <i className="mdi mdi-web me-2" /> {props.t("Ensuro Website")}
              </p>
            </a>
          </DropdownItem>
          <DropdownItem>
            <a target="_blank" rel="noopener noreferrer" href={"https://blog.ensuro.co"}>
              <p className="font-size-16 no-margin-black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-medium"
                  viewBox="0 0 16 16"
                  style={{ marginRight: "8px" }}
                >
                  <path d="M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8zm4.95 0c0 2.34-1.01 4.236-2.256 4.236-1.246 0-2.256-1.897-2.256-4.236 0-2.34 1.01-4.236 2.256-4.236 1.246 0 2.256 1.897 2.256 4.236zM16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795z" />
                </svg>{" "}
                {props.t("Blog")}
              </p>
            </a>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

HeaderButtons.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(HeaderButtons);
