import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
/* redux */
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { changeLayout } from "../../store/actions";
import { environment } from "../../config";

const Layout = ({ changeLayout, children }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const title = location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    if (environment.testnet) {
      document.title = currentage + " | Ensuro | ** TESTNET ** | Decentralized capital for insurance";
    } else {
      document.title = currentage + " | Ensuro | Decentralized capital for insurance";
    }

    changeLayout("horizontal");
  }, [location.pathname, changeLayout]);

  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <header id="page-topbar">
          <Header isMenuOpened={isMenuOpened} openLeftMenuCallBack={openMenu} />
          <Navbar menuOpen={isMenuOpened} />
        </header>
        <div className="main-content">{children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayout: PropTypes.func,
  children: PropTypes.object,
};

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default connect(mapStatetoProps, { changeLayout })(Layout);
