// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { CHANGE_LAYOUT, CHANGE_TOPBAR_THEME } from "./actionTypes";

import { changeTopbarTheme as changeTopbarThemeAction } from "./actions";

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeLayout({ payload: layout }) {
  try {
    yield put(changeTopbarThemeAction("colored"));
    document.body.removeAttribute("data-sidebar");
    document.body.removeAttribute("data-sidebar-size");
    yield call(changeBodyAttribute, "data-layout", layout);
  } catch (error) {}
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
function* changeTopbarTheme({ payload: theme }) {
  try {
    yield call(changeBodyAttribute, "data-topbar", theme);
  } catch (error) {}
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
  yield takeEvery(CHANGE_LAYOUT, changeLayout);
}

export function* watchChangeTopbarTheme() {
  yield takeEvery(CHANGE_TOPBAR_THEME, changeTopbarTheme);
}

function* LayoutSaga() {
  yield all([fork(watchChangeLayoutType), fork(watchChangeTopbarTheme)]);
}

export default LayoutSaga;
