import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { toggleLeftmenu } from "../../store/actions";

// Import walletBtn
import ConnectButton from "../CommonForBoth/ConnectButton";
import SwitchNetworkButton from "../CommonForBoth/SwitchNetworkButton";

import logoSm from "../../assets/images/logo-sm-negative.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoNegative from "../../assets/images/negative-ensuro.png";

//i18n
import { withTranslation } from "react-i18next";
import HeaderButtons from "./HeaderButtons";
import SettingsDropdown from "../CommonForBoth/TopbarDropdown/SettingsDropdown";

const Header = (props) => {
  return (
    <React.Fragment>
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="160" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoNegative} alt="" height="160" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect vertical-menu-btn"
            data-toggle="collapse"
            onClick={() => {
              props.toggleLeftmenu(!props.leftMenu);
            }}
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button>

          <div className="d-none d-lg-inline-block header-buttons">
            <HeaderButtons />
          </div>
        </div>

        <div className="d-flex">
          <SwitchNetworkButton user={props.user} />
          <ConnectButton user={props.user} />
          <SettingsDropdown />
        </div>
      </div>
    </React.Fragment>
  );
};

Header.propTypes = {
  t: PropTypes.any,
  leftMenu: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, leftMenu } = state.Layout;
  const user = state.UserReducer;
  return { layoutType, leftMenu, user };
};

export default connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Header));
