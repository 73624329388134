import React, { useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { Row, Col } from "react-bootstrap";
import Loader from "react-js-loader";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
/* Store */
import { selectAPICallMultiple } from "../../store/api/selectors";
import { dispatchAPICalls } from "../../helpers/api_store_helper";
import moment from "moment";
import "./style.scss";

const apiCalls = (apiName, address, daysFrom) => {
  return [{ apiName: apiName, args: [address, daysFrom] }];
};

const ModalChart = ({ t, apiCall, address, daysFrom, data, dataKey, color, type, yLabel }) => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatchAPICalls(dispatch, apiCalls(apiCall, address, daysFrom));
  }, [address, apiCall, daysFrom, dispatch]);

  function CustomTooltip({ payload, label, active }) {
    if (active && payload && payload?.length > 0) {
      return (
        <div className="custom-tooltip">
          <b>{moment(label).format("ll")} </b>
          <p style={{ color: color }}>{`${yLabel}: ${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  }

  return (
    <>
      {data.state !== "LOADED" ? (
        <div className="page-content loader">
          <Loader type="spinner-default" height={50} width={100} timeout={1000} />
        </div>
      ) : (
        <Row>
          <Col md={12} align="center">
            {type === "Bar" && (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart width={500} height={300} data={data.value}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey={"date"}
                    label={{ value: t("Date"), position: "insideCenter", dy: 15 }}
                    tickFormatter={(timeStr) => moment(timeStr).format("ll")}
                  />
                  <YAxis label={{ value: yLabel, fill: color, angle: -90, position: "insideLeft", dx: -5 }} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey={dataKey} fill={color} />
                </BarChart>
              </ResponsiveContainer>
            )}
            {type === "Line" && (
              <ResponsiveContainer width="100%" height={400}>
                <LineChart width={500} height={300} data={data.value}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey={"date"}
                    label={{ value: t("Date"), position: "insideCenter", dy: 15 }}
                    tickFormatter={(timeStr) => moment(timeStr).format("ll")}
                  />
                  <YAxis label={{ value: yLabel, fill: color, angle: -90, position: "insideLeft", dx: -5 }} />
                  <Tooltip content={<CustomTooltip />} />
                  <Line type="monotone" dataKey={dataKey} stroke={color} dot={false} strokeWidth="3" />
                </LineChart>
              </ResponsiveContainer>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
ModalChart.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const address = ownProps.address;
  const daysFrom = ownProps.daysFrom;
  const apiCall = ownProps.apiCall;

  const [data] = selectAPICallMultiple(state.APIReducer, apiCalls(apiCall, address, daysFrom));
  return { data };
};

export default connect(mapStateToProps)(withTranslation()(ModalChart));
