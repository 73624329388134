import React, { useState } from "react";
import { Row, Button } from "react-bootstrap";

const DaysFilter = ({ title, setDays }) => {
  const [clicked, setClicked] = useState("3M"); // Default 3M

  return (
    <Row className="days-buttons-padding">
      <div align="left" className="cashflow-title">
        <h4>{title}</h4>
      </div>
      <div align="right">
        <Button
          className="risk-button"
          size="sm"
          variant={clicked === "7D" ? "secondary" : "sky-blue"}
          onClick={() => {
            setDays(7); // 7 days
            setClicked("7D");
          }}
        >
          7D
        </Button>
        <Button
          className="risk-button"
          size="sm"
          variant={clicked === "1M" ? "secondary" : "sky-blue"}
          onClick={() => {
            setDays(30); // 1 month = 30 days
            setClicked("1M");
          }}
        >
          1M
        </Button>
        <Button
          className="risk-button"
          size="sm"
          variant={clicked === "3M" ? "secondary" : "sky-blue"}
          onClick={() => {
            setDays(90); // 3 months = 90 days
            setClicked("3M");
          }}
        >
          3M
        </Button>
        <Button
          className="risk-button"
          size="sm"
          variant={clicked === "6M" ? "secondary" : "sky-blue"}
          onClick={() => {
            setDays(180); // 6 months = 180 days
            setClicked("6M");
          }}
        >
          6M
        </Button>
        <Button
          className="risk-button"
          size="sm"
          variant={clicked === "ALL" ? "secondary" : "sky-blue"}
          onClick={() => {
            setDays(null);
            setClicked("ALL");
          }}
        >
          ALL
        </Button>
      </div>
    </Row>
  );
};

export default DaysFilter;
