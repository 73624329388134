import { chain } from "../config";
import { selectEtkByAddress } from "../store/eTokens/selectors";
import { selectPAByAddress } from "../store/premiumsAccounts/selectors";

export function getUtilizationRate(totalSupply, scr) {
  if (totalSupply.state === "LOADED" && scr.state === "LOADED") {
    return totalSupply.value === 0 ? 0 : ((scr.value / totalSupply.value) * 100).toFixed(1);
  }
  return undefined;
}

export function calcOnLoad(fn, ...variables) {
  if (variables.every((variable) => variable.state === "LOADED")) {
    const values = variables.map((variable) => variable.value);
    return { state: "LOADED", value: fn(...values) || 0 };
  }
  return { state: "LOADING", value: undefined };
}

export function etherscanLink(address) {
  return chain.scan + "address/" + address;
}

export function getAllPremiumsAccountsByEtk(etkPAs, allPAs) {
  let paInEtk = [];
  if (etkPAs && allPAs) {
    etkPAs.forEach((pa) => {
      const addr = new URL(pa).pathname.split("/")[3];
      paInEtk.push({
        address: addr,
        name: allPAs[addr],
      });
    });
  }
  return paInEtk;
}

export function checkVisibility(obj, mode) {
  return obj.custom_data && !(obj.custom_data.tags && obj.custom_data.tags.includes("hidden") && mode === "hide");
}

export function isJrOfSrFilter(etk, srEtkFilter, state, mode) {
  return etk.sr_etks?.some((srEtk) => {
    const addr = new URL(srEtk).pathname.split("/")[3];
    return srEtkFilter.includes(addr) && checkVisibility(selectEtkByAddress(state, addr), mode);
  });
}

export function isSrPool(etk) {
  return etk.sr_for_pas?.length > 0;
}

export function getRiskParams(params) {
  const moc = params?.value ? { value: params.value[0], state: "LOADED" } : {};
  const jrCollRatio = params?.value ? { value: params.value[1], state: "LOADED" } : {};
  const srCollRatio = params?.value ? { value: params.value[2], state: "LOADED" } : {};
  const ensuroFee = params?.value ? { value: params.value[3], state: "LOADED" } : {};
  const ensuroCoCFee = params?.value ? { value: params.value[4], state: "LOADED" } : {};
  const jrRoc = params?.value ? { value: params.value[5], state: "LOADED" } : {};
  const srRoc = params?.value ? { value: params.value[6], state: "LOADED" } : {};
  return { moc, jrCollRatio, srCollRatio, ensuroFee, ensuroCoCFee, jrRoc, srRoc };
}

export function getEtkWithURL(reducer, url) {
  const addr = url && new URL(url).pathname.split("/")[3];
  const etk = selectEtkByAddress(reducer, addr);
  return etk ? { state: "LOADED", value: { name: etk.name, address: etk.address } } : {};
}

export function getPAWithURL(reducer, url) {
  const addr = url && new URL(url).pathname.split("/")[3];
  const pa = selectPAByAddress(reducer, addr);
  return pa ? { state: "LOADED", value: { name: pa.name, address: pa.address } } : {};
}
