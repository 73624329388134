// Risks Redux States
import { GET_RISKS_MODULES, GET_RISKS_MODULES_SUCCESS, GET_RISKS_MODULES_FAIL, SET_SELECTED_RISK } from "./actionTypes";

const INIT_STATE = {
  risks: [],
  loadingState: "NOT_LOADED",
  // NOT_LOADED -> Loading -> LOADED
  //                       \-> ERROR
  selectedRisk: "",
  error: {
    message: "",
  },
};

const RisksReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RISKS_MODULES:
      state = { ...state, loadingState: "LOADING" };
      break;

    case GET_RISKS_MODULES_SUCCESS:
      state = { ...state, risks: action.payload, loadingState: "LOADED" };
      break;

    case GET_RISKS_MODULES_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingState: "ERROR",
      };
      break;

    case SET_SELECTED_RISK:
      state = {
        ...state,
        selectedRisk: action.address,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default RisksReducer;
