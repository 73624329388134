import React from "react";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Table
import ETokensTable from "../../components/Table/ETokenTable/ETokensTable";
import etkSymbol from "../../assets/images/etoken_color.svg";

const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <br />
          <Breadcrumbs title="Ensuro" breadcrumbItem="Pools" logo={etkSymbol} />
          <br />
          <ETokensTable />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
