import { registerProvider } from "../providers";

export function refreshWallet(dispatch, provider, user, chainId, canTransact) {
  dispatch({
    type: "SET_PROVIDER",
    payload: registerProvider(provider),
  });
  dispatch({
    type: "SET_USER",
    payload: user,
    canTransact: canTransact,
  });
  dispatch({
    type: "SET_CHAIN_ID",
    payload: chainId,
  });
}

export function shortAddress(address) {
  return address?.substring(0, 6) + "..." + address.slice(-4);
}
