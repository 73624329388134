import Big from "big.js";

export function setPercentages(data, objList, key, dataKey = "rm") {
  data.sort((a, b) => b[key] - a[key]);
  const t = data.reduce((acc, r) => {
    return r[key] > 0 ? acc.add(r[key]) : acc;
  }, Big(0));
  let ret = [];
  let count = 0;
  let sum = Big(0);
  data.forEach((r, i) => {
    if (r[key] <= 0) return;
    if (i < 3) {
      ret.push({
        address: r[dataKey],
        name: objList[r[dataKey]] || r[dataKey],
        value: (r[key] / t) * 100,
        total: r[key],
      });
    } else {
      sum = sum.add(r[key]);
      count += (r[key] / t) * 100;
    }
  });
  if (count > 0) ret.push({ name: "Others", value: count, total: sum });
  return ret;
}
