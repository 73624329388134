import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import BlurredNumber from "../../CommonForBoth/Blurred/BlurredNumber";
import { Container, Row, Col } from "react-bootstrap";
import Badges from "../../CommonForBoth/Badges";
import { Card } from "semantic-ui-react";
/* Store */
import { selectAPICallMultiple } from "../../../store/api/selectors";
import { selectEthCallMultiple } from "ethereum-store/src/store/ethereum/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
/* Helpers */
import { calcOnLoad } from "../../../helpers/ensuro_helper";
import { refreshAPICalls } from "../../../helpers/api_store_helper";
import { apy, images } from "../../../config";
import "./../style.scss";

const makeEthCalls = function (etkAddress) {
  return [
    { address: etkAddress, abi: "EToken", method: "totalSupply", args: [] },
    { address: etkAddress, abi: "EToken", method: "scr", args: [] },
    { address: etkAddress, abi: "EToken", method: "minUtilizationRate", args: [] },
  ];
};

const makeAPICalls = (etkAddress) => {
  return [{ apiName: "apy", args: [etkAddress, apy.range] }];
};

const ETokenRowMobile = ({ t, eToken, idx, totalSupply, scr, minUtilizationRate, apy, poolBadges }) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const mounted = useRef(false);
  let tags = eToken.custom_data?.tags || [];

  const isOversold = calcOnLoad((ts, scr, minUR) => scr / ts < minUR, totalSupply, scr, minUtilizationRate);
  if (isOversold.value === true) {
    tags = [...tags, "oversold"];
  }

  const handleCardClick = (address) => {
    navigate("/eTokens/" + address);
  };

  useEffect(() => {
    mounted.current = true;
    dispatch({
      type: "ETH_ADD_SUBSCRIPTION",
      key: "etkMobile" + eToken.address,
      componentEthCalls: makeEthCalls(eToken.address),
    });
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "etkMobile" + eToken.address });
      mounted.current = false;
    };
  }, [dispatch, eToken.address]);

  useEffect(() => {
    return refreshAPICalls(dispatch, makeAPICalls(eToken.address));
  }, [eToken.address, dispatch]);

  return (
    <Container className="mobile">
      <Card className="card-row card-hover primary-colored" onClick={() => handleCardClick(eToken.address)} key={idx}>
        <Container>
          <Row className="table-header">
            <Col xs={12} className="align-self-center name-with-logo" style={{ paddingBottom: 0 }}>
              <strong style={{ color: "black" }}>
                <span className="logo-sm">
                  <img src={eToken.logo || images.defaultEtkLogo} alt="" height="22" />
                </span>{" "}
                {eToken.name} ({eToken.symbol})
              </strong>
            </Col>
            <Col xs={12} className="align-self-center">
              <Badges tags={tags} badgesData={poolBadges} />
            </Col>
            <br />
            <br />
            <Col xs={4}>
              <strong>{t("APY")}</strong>
            </Col>
            <Col xs={4}>
              <strong>{t("Liquidity")}</strong>
            </Col>
            <Col xs={4}>
              <strong>{t("Utilization Rate")}</strong>
            </Col>
            <Col xs={4}>
              <Card className="card-inside" style={{ color: "black", marginTop: "10px", marginBottom: "10px" }}>
                <BlurredNumber
                  number={apy}
                  suffix={"%"}
                  percentage={true}
                  options={{ maximumFractionDigits: 2, notation: "standard" }}
                />
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="card-inside" style={{ color: "black", marginTop: "10px", marginBottom: "10px" }}>
                <BlurredNumber number={totalSupply} options={{ maximumFractionDigits: 2, notation: "compact" }} />
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="card-inside" style={{ color: "black", marginTop: "10px", marginBottom: "10px" }}>
                <BlurredNumber
                  number={calcOnLoad((a, b) => a / b, scr, totalSupply)}
                  options={{ maximumFractionDigits: 2, notation: "standard" }}
                  percentage={true}
                  suffix={"%"}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </Card>
    </Container>
  );
};

ETokenRowMobile.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const etkAddress = ownProps.eToken.address;
  const [totalSupply, scr, minUtilizationRate] = selectEthCallMultiple(state.EthereumReducer, makeEthCalls(etkAddress));
  const [apy] = selectAPICallMultiple(state.APIReducer, makeAPICalls(etkAddress));
  return { totalSupply, scr, minUtilizationRate, apy };
};

export default connect(mapStateToProps)(withTranslation()(ETokenRowMobile));
