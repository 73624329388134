import React, { useEffect } from "react";
/* Components */
import PAs from "./PAs";
/* Selectors */
import { selectAPICall } from "../../../store/api/selectors";
import { selectSetting } from "../../../store/settings/selectors";
/* Redux */
import * as actionTypes from "../../../store/premiumsAccounts/actionTypes";
import { connect, useDispatch } from "react-redux";

const PATable = ({ pas, state, mode }) => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: actionTypes.SET_SELECTED_PA, address: "" });
  }, [dispatch]);

  const getActivePolicies = function (pa) {
    return selectAPICall(state.APIReducer, "paActivePolicies", [pa.address]) || -1;
  };
  const sortedPAs = [...pas].sort((p1, p2) => getActivePolicies(p2) - getActivePolicies(p1));

  return <PAs pas={sortedPAs} state={state.PAReducer} mode={mode} />;
};

const mapStateToProps = (state) => {
  const pas = state.PAReducer.pas;
  const mode = selectSetting(state.SettingsReducer, "VIEW_MODE");

  return { pas, state, mode };
};

export default connect(mapStateToProps)(PATable);
