import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Card, Row, Col } from "react-bootstrap";
import { compactNumber } from "../../helpers/number_format_helper";
import CustomTooltip from "../CommonForBoth/CustomTooltip";
import "./single_eToken.scss";

const Progress = ({ t, title, value, variant, minUR, maxUR }) => {
  minUR = minUR && minUR.value ? compactNumber(minUR.value * 100) : undefined;
  maxUR = maxUR && maxUR.value ? compactNumber(maxUR.value * 100) : undefined;

  return (
    <Card className="progress-col etk-card-detail-inside">
      <Row>
        <Col md={6} xs={8} className="font-size-20 without-padding">
          {title}
        </Col>
        <Col md={6} xs={4} className="font-size-20 right-align-value primary-colored without-padding">
          {value}%
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12} className="without-padding">
          <div className="progress">
            {minUR && (
              <CustomTooltip
                text={
                  <>
                    <p>
                      {t("The minimum utilization rate (UR) is set at ")} {minUR}%
                    </p>
                    <p>{t("MinURTooltip")}</p>
                  </>
                }
                placement="bottom"
              >
                <div className="bar-step " style={{ left: `${minUR}%` }}>
                  <div className="label-line"></div>
                </div>
              </CustomTooltip>
            )}
            {maxUR && (
              <CustomTooltip
                text={
                  <>
                    <p>
                      {t("The maximum utilization rate (UR) is set at ")} {maxUR}%
                    </p>
                    <p>{t("MaxURTooltip")}</p>
                  </>
                }
                placement="bottom"
              >
                <div className="bar-step" style={{ left: `${maxUR}%` }}>
                  <div className="label-line"></div>
                </div>
              </CustomTooltip>
            )}
            <div className={"progress-bar bg-" + variant} style={{ width: `${value}%` }} />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

Progress.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Progress);
