import _ from "lodash";
import { refresh } from "../config";

export function dispatchCall(dispatch, call) {
  return dispatch({
    type: "ETH_CALL",
    address: call.address,
    abi: call.abi,
    method: call.method,
    args: call.args,
  });
}

export function dispatchCalls(dispatch, calls) {
  _.forEach(calls, (call) => dispatchCall(dispatch, call));
}

/**
 * Dispatchs calls every `refreshInterval || refresh.default`
 */
export function refreshCalls(dispatch, calls, refreshInterval = undefined, skipFirst = false) {
  if (!skipFirst) {
    dispatchCalls(dispatch, calls);
  }
  const interval = setInterval(() => dispatchCalls(dispatch, calls), refreshInterval || refresh.default);
  return () => clearInterval(interval);
}

export function validTransaction(lastTransaction) {
  return !lastTransaction || !(lastTransaction && !lastTransaction.state && lastTransaction.state !== "QUEUED");
}

export function customErrorParser(error) {
  const stringToFind = 'reason":"';
  let custom = JSON.stringify(error);
  if (custom && custom.includes(stringToFind)) {
    let start_index = custom.indexOf(stringToFind) + stringToFind.length;
    let end_index = custom.indexOf('",', start_index);
    let new_string = custom.slice(start_index, end_index);
    return new_string;
  }
  return undefined;
}
