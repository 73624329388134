import React from "react";
import * as numberFormat from "../../../helpers/number_format_helper";
import "../style.scss";

const BlurredNumber = ({ number, suffix, options, percentage }) => {
  return (
    <>
      {number && number.state !== "LOADED" ? (
        <span className="blur">999 {suffix}</span>
      ) : (
        <>
          {numberFormat.compactNumber(number.value * (percentage ? 100 : 1), options)}
          {suffix}
        </>
      )}
    </>
  );
};

export default BlurredNumber;
