import { createSelector } from "reselect";
import _ from "lodash";

const getPAList = (state) => state.pas;
const getLoadingState = (state) => state.loadingState;
const getSelectedPA = (state) => state.selectedPA;

// When exists return an index >= 0
// When NOT exists returns -1
// When I don't know yet because pas not loaded returns undefined
export const selectCurrentExists = createSelector(
  [getPAList, getSelectedPA, getLoadingState],
  (pas, current, loadingState) => {
    if (loadingState !== "LOADED") return undefined;
    return _.findIndex(pas, (pa) => pa.address === current);
  }
);

export const selectCurrent = createSelector([getPAList, getSelectedPA], (pas, current) => {
  return _.find(pas, (pa) => pa.address === current);
});

export const selectCurrentAddr = createSelector(getSelectedPA, (current) => current);

export const selectCurrentIsReady = createSelector([selectCurrent], (current) => {
  return current !== undefined && current.data !== undefined;
});

export const selectAllReady = createSelector([getPAList, getLoadingState], (pas, loadingState) => {
  let pa = _.find(pas, (pa) => pa.data === undefined);
  return pas.length > 0 && loadingState === "LOADED" && !pa;
});

export const selectPAByAddress = createSelector([getPAList, (__, address) => address], (pas, address) => {
  let pa = _.find(pas, (pa) => pa.address === address);
  return pa;
});

export const selectPANames = createSelector([getPAList], (pas) => {
  let dict = {};
  pas.forEach((pa) => {
    dict[pa.address] = pa.name;
  });
  return dict;
});
