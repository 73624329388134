// Dashboard
import Dashboard from "../pages/Dashboard/dashboard";
// Risks
import Risks from "../pages/RisksDashboard/risks";
// PremiumsAccounts
import PremiumsAccounts from "../pages/PADashboard/premiumsaccounts";

import EToken from "../pages/EToken/eToken";
import Risk from "../pages/Risk/risk";
import PA from "../pages/PremiumsAccounts/pa";
import GetAQuote from "../pages/Forms/GetAQuote.js";
import NotFound from "../pages/NotFound/notFound";

const userRoutes = [
  { path: "/", component: Dashboard },
  { path: "/eTokens", component: Dashboard },
  { path: "/risks", component: Risks },
  { path: "/portfolios", component: PremiumsAccounts },
  { path: "/eTokens/:name", component: EToken },
  { path: "/risks/:name", component: Risk },
  { path: "/portfolios/:name", component: PA },
  { path: "/get-a-quote", component: GetAQuote },

  // Not found page
  { path: "*", component: NotFound },
];

const authRoutes = [];

export { userRoutes, authRoutes };
