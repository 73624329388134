import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalVideo from "react-modal-video";
import { Row, Col } from "react-bootstrap";
import { Card } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import imgIcon from "../../assets/images/image-icon.png";
import docsIcon from "../../assets/images/docs-icon.png";
import websiteIcon from "../../assets/images/website-icon.png";
import videoIcon from "../../assets/images/video-icon.png";
import defaultIcon from "../../assets/images/default-icon.png";
import "react-modal-video/scss/modal-video.scss";
import colors from "../../colors";
import "./style.scss";

const iconType = {
  image: imgIcon,
  pdf: docsIcon,
  website: websiteIcon,
  video: videoIcon,
};

const MoreInfoDocs = ({ t, docs }) => {
  const [isOpen, setOpen] = useState(false);
  const [video, setVideo] = useState();

  function ShowImage({ imageKey }) {
    const src = imageKey ? iconType[imageKey] : defaultIcon;
    return <img src={src} alt={imageKey} height="30" />;
  }

  const openModal = (data) => {
    setOpen(true);
    setVideo(new URL(data.url).pathname.split("/")[1]);
  };

  const closeModal = () => {
    setOpen(false);
    setVideo();
  };

  return (
    <>
      <Card fluid className="params">
        <h4>{t("More information")}</h4>
        <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />
        <Row>
          {docs.map((d, index) => (
            <React.Fragment key={index}>
              {d.type === "video" ? (
                <>
                  <Col md={2} xs={2} className="docs-row-margin">
                    <span
                      className="centered"
                      style={{ cursor: "pointer", color: "#0055B8" }}
                      onClick={() => openModal(d)}
                    >
                      <ShowImage imageKey={d.type} />
                    </span>{" "}
                  </Col>
                  <Col md={10} xs={10} className="docs-row-margin">
                    <div style={{ cursor: "pointer", color: "#0055B8" }} onClick={() => openModal(d)}>
                      {d.name}
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={2} xs={2} className="docs-row-margin">
                    <a href={d.url} target="_blank" rel="noreferrer">
                      <span className="centered">
                        <ShowImage imageKey={d.type} />
                      </span>
                    </a>{" "}
                  </Col>
                  <Col md={10} xs={10} className="docs-row-margin">
                    <a href={d.url} target="_blank" rel="noreferrer">
                      {d.name}
                    </a>
                  </Col>
                </>
              )}
            </React.Fragment>
          ))}
        </Row>
      </Card>
      <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId={video} onClose={closeModal} />
    </>
  );
};

MoreInfoDocs.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(MoreInfoDocs);
