// ETokens Redux States
import { GET_ETOKENS, GET_ETOKENS_SUCCESS, GET_ETOKENS_FAIL, SET_SELECTED_ETOKEN } from "./actionTypes";

const INIT_STATE = {
  eTokens: [],
  loadingState: "NOT_LOADED",
  // NOT_LOADED -> Loading -> LOADED
  //                       \-> ERROR
  selectedEToken: "", // --> selected eToken address
  error: {
    message: "",
  },
};

const ETokensReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ETOKENS:
      state = { ...state, loadingState: "LOADING" };
      break;

    case GET_ETOKENS_SUCCESS:
      state = { ...state, eTokens: action.payload, loadingState: "LOADED" };
      break;

    case GET_ETOKENS_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loadingState: "ERROR",
      };
      break;

    case SET_SELECTED_ETOKEN:
      state = {
        ...state,
        selectedEToken: action.address,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ETokensReducer;
